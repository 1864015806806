import React from 'react';
const TrustBox = ({templateId, height}) => {
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = React.useRef(null);
    React.useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (
        <div
            ref={ref} // We need a reference to this element to load the TrustBox in the effect.
            className="trustpilot-widget" // Renamed this to className.
            // [ long list of data attributes...]
            data-locale="fr-FR" 
            data-template-id={templateId}
            data-businessunit-id="614af0a1673c15001d501ea5" 
            data-style-height={height}
            data-style-width="100%" 
            data-theme="light" 
            data-stars="1,2,3,4,5" 
            data-no-reviews="hide" 
            data-scroll-to-list="true" 
            data-allow-robots="true"
        >
            <a href="https://fr.trustpilot.com/review/portail-des-societes.com" target="_blank" rel="noopener">Trustpilot</a>
        </div>
    );
};
export default TrustBox;