import api from "./api";

export const getCommentary = async (customer) => {
    var response = await api.get("api/getcommentary",{
        headers: {
            Accept: "application/json",
        },
    }).catch((error) => {
        console.log(error);
        return false;
    });
    if (response.data) return response.data;
    else return false;
}
