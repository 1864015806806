import api from "./api";


export const getSocietes = async (query) => {
    var societes = await api.get(`/api/societe?query=${query}`).catch((error) => {
        console.log(error);
        return false;
    });
    if (societes) return societes;
    else return false;
}

export const hasKbis = async (siren) => {
    var hasKbis = await api.get(`/api/societe/haskbis?siren=${siren}`).catch((error) => {
        console.log(error);
        return false;
    });
    if (hasKbis) return hasKbis;
    else return false;
}