import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress, Box
} from '@material-ui/core';
import MainInput from "./MainInput";
import MainCheckbox from "./MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import RegularInput from "./RegularInput";
import { AuthContext } from "../contexts/AuthContext.js";
import api from "../services/api.js";
import Alert from "@material-ui/lab/Alert";
import { downloadKbis, getKbis } from "../services/FileService";
import { useInterval } from "./Hooks";
import SearchCircular from "./SearchCircular";
import fileDownload from 'js-file-download';
import { getInvoices, getTotalProcessingInvoices } from "../services/AccountService";
import { TotalProcessingBill } from "./totalprocessing/TotalProcessingBill";


const AccountInvoice = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [openError, setOpenError] = useState(false);
    const history = useHistory();
    const { auth, authMethods } = useContext(AuthContext);
    const [invoices, setInvoices] = useState([]);
    const [totalProcessingInvoices, setTotalProcessingInvoices] = useState([]);


    async function getAndSetStripeInvoices() {
        var invoiceResult = await getInvoices(auth);
        if (invoiceResult.data) {
            var invoiceFilteredSuccess = invoiceResult.data.filter((item) => item.status == "paid");
            var invoicesOrderedByDate = invoiceFilteredSuccess.sort(function (a, b) {
                return new Date(b.create) - new Date(a.create);
            });
            setInvoices(invoicesOrderedByDate);
        }
    }

    async function getAndSetTotalProcessingInvoices() {
        var totalProcessingResult = await getTotalProcessingInvoices(auth);
        if (totalProcessingResult.data) {
            var invoicesOrderedByDate = totalProcessingResult.data.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });
            setTotalProcessingInvoices(invoicesOrderedByDate)
        }
    }

    useEffect(() => {
        getAndSetStripeInvoices();
        getAndSetTotalProcessingInvoices();
    }, []);

    const desktop = useMediaQuery('(min-width:769px)');

    const downloadFile = async (fileURL) => {
        window.open(fileURL, "_blank");
    }

    const downloadTotalProcessingInvoice = (id) => {
        const html2pdf = require('html2pdf.js');

        var element = document.getElementById("tpinvoice-" + id);

        html2pdf().from(element).set({
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                dpi: 192,
                scale: 5,
                letterRendering: true,
                useCORS: true
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            margin: [0, 0, 0, 0],
        }).toPdf().save("Facture-" + id + ".pdf");
    }

    return (
        <div className="account-container">
            <div className="account-title">Factures</div>
            <div className="account-body">

                <Grid container direction="column">
                    {totalProcessingInvoices.map((row, index) =>
                        <Grid item key={index} style={{ marginBottom: "10px" }}>
                            <Grid container alignItems="center" justify="space-between"  >
                                <Grid item>Date : {row.date ? new Date(row.date).toLocaleString() : ''} </Grid>
                                <Grid item>Montant : {row.amount} € </Grid>
                                <Grid item>
                                    <Button onClick={() => downloadTotalProcessingInvoice(row.invoiceNumber)} color="primary" variant="outlined" className="header-button">Télécharger</Button>
                                </Grid>
                            </Grid>
                            <Box style={{ visibility: "hidden", height: "0px" }}>
                                <TotalProcessingBill invoice={row} />
                            </Box>
                        </Grid>
                    )}
                    {invoices.map((row, index) =>
                        <Grid item key={index} style={{ marginBottom: "10px" }}>
                            <Grid container alignItems="center" justify="space-between"  >
                                <Grid item>Date : {row.created ? new Date(row.created * 1000).toLocaleString() : ''} </Grid>
                                <Grid item>Montant : {row.amount_paid / 100} € </Grid>
                                <Grid item>
                                    <Button onClick={() => downloadFile(row.invoice_pdf)} color="primary" variant="outlined" className="header-button">Télécharger</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                </Grid>
            </div>
        </div >
    );
};

export default AccountInvoice;
