import React, { useState, useContext } from "react";
import Footer from '../layout/footer';
import Header from './header2';
import {
    Container
} from '@material-ui/core'
import Header2 from "./header2";

function RootLayout2(props) {
    return (
        <div className="page-container">
            <div className="main-with-header">
                <Header2></Header2>
                <div className="main">
                    <Container>
                        <div className="main-content">
                            {props.children}
                        </div>
                    </Container>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default RootLayout2