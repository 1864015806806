import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Container
} from '@material-ui/core';
import Footer from '../layout/footer';
import Alert from "@material-ui/lab/Alert";
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import { AuthContext } from "../contexts/AuthContext.js";
import { createKbis } from "../services/FileService";
import HeaderLP from "../lp/headerLP";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import '../LP.css';
import Reassurance from "../lp/reassurance";
import Services from "../lp/services";
import Tool from "../lp/tool";
import Clients from "../lp/clients";
import Register from "../lp/register";
import CTA from "../lp/cta";
import Pros from "../lp/pros";
import Companies from "../lp/companies";
import Newsletter from "../lp/newsletter";
import GoTop from "../lp/goTop";
import Scroll from "react-scroll";
import TrustBox from "../components/TrustBox";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import YouTubeIcon from '@material-ui/icons/YouTube';
import VideoDialog from "../lp/videoDialog";

const ScrollLink = Scroll.Link;

var dataLayer;

const LP = () => {

    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const desktop = useMediaQuery('(min-width:769px)');
    const { auth } = useContext(AuthContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const goToFunnel = () => {
        history.push("/inscription");
    }

    return (<div className="page-container">
        <div className="main-with-header lp-main-with-header">
            <img src={desktop ? "/img/lp/bg-1.svg" : "/img/lp/bg-1-mobile.svg"} className="lp-bg-1"></img>
            <img src="/img/lp/bg-2.svg" className="lp-bg-2"></img>
            <HeaderLP></HeaderLP>
            <div className="main">
                <Container>
                    <div className="main-content lp-main-content">
                        <Grid container direction="row">
                            <Grid item xs={12} md={7}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container alignItems="center" spacing={2} style={{ marginBottom: "20px" }} className="lp-pub-tv-button" justify={desktop ? "flex-start" : "center"} onClick={handleClickOpen} >

                                            <YouTubeIcon color="primary" fontSize="large" />
                                            <Grid item>
                                                <div className="lp-pub-tv-text">Spot Pub</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <div className="lp-value-proposition">
                                            <span className="color2">Accédez à tous vos documents pros</span> <br />
                                            Depuis une seule plateforme pour <br />
                                            <span className="color2">49,90€ par mois</span>
                                        </div>
                                    </Grid>
                                    <Grid item className="lp-cta-container">
                                        <Grid container direction={desktop ? "row" : "column"} alignItems={desktop ? "flex-start" : "center"} justify={desktop ? "flex-start" : "center"} spacing={3}>
                                            <Grid item> <Button variant="contained" endIcon={<ArrowForwardIcon className="big-icon" />} color="primary" className="lp-cpa-button-2" onClick={goToFunnel}>Profitez de l'offre d'essai</Button> </Grid>
                                            <Grid item>
                                                <ScrollLink
                                                    className="navy"
                                                    smooth={true}
                                                    duration={500}
                                                    to="lp-services"
                                                    offset={-127}
                                                >
                                                    <Button variant="outlined" endIcon={<ArrowForwardIcon className="big-icon" />} color="secondary" className="lp-cpa-button-2 lp-cta-secondary"> Découvrir nos services </Button>
                                                </ScrollLink>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <div className="lp-cta-description">
                                            Valable 48h - Sans engagement
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className="lp-trustpilot">
                                            <TrustBox templateId={"5419b6a8b0d04a076446a9ad"} height={"20px"}></TrustBox>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <div className="lp-phone-top-container"><img src={desktop ? "/img/lp/phone-top.svg" : "/img/lp/phone-top-mobile.svg"} className="lp-phone-top"></img></div>

                            </Grid>
                        </Grid>
                    </div>
                </Container>
                <div className="lp-linear-gradient">
                    <Container>
                        <Reassurance></Reassurance>
                    </Container>
                    <Container className="lp-widther-section">
                        <Services></Services>
                    </Container>
                </div>
                <Tool></Tool>
                <div className="lp-container-clients">
                    <img src="/img/lp/bg-3.svg" className="lp-bg-3 display-desktop"></img>
                    <Container className="lp-widther-section">
                        <Clients></Clients>
                    </Container>
                </div>
                <div className="lp-container-register">
                    <img src="/img/lp/bg-4.svg" className="lp-bg-4 display-desktop"></img>
                    <Container>
                        <Register></Register>
                    </Container>
                </div>

                <div className="lp-container-cta"  >
                    <img src={desktop ? "/img/lp/bg-5.svg" : "img/lp/lp-bg-5-mobile.svg"} className="lp-bg-5"></img>
                    <img src={desktop ? "/img/lp/bg-6.svg" : "img/lp/lp-bg-6-mobile.svg"} className="lp-bg-6"></img>
                    <img src={desktop ? "/img/lp/phone-cta.svg" : "/img/lp/lp-phone-cta-mobile.svg"} className="lp-phone-cta"></img>
                    <CTA></CTA>
                </div>

                <div className="lp-container-pros">
                    <Container className="lp-widther-section">
                        <Pros></Pros>
                    </Container>
                </div>

                <div className="lp-container-companies">
                    <img src="/img/lp/bg-7.svg" className="lp-bg-7 display-desktop"></img>
                    <Container >
                        <Companies></Companies>
                    </Container>
                </div>
                <Newsletter></Newsletter>


            </div>
        </div>
        <Footer></Footer>
        <GoTop scrollStepInPx="100" delayInMs="10.50" />
        <VideoDialog open={open} onClose={handleClose} />
    </div>
    )
}
export default LP;