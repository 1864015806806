import React, { useContext } from 'react';
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from '../../contexts/AuthContext';
const PrivateRoute = ({ children, ...rest }) => {
  const { auth } = useContext(AuthContext);
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (auth.user && auth.accessToken && auth.rememberToken) ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}


export default PrivateRoute;