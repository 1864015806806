import React, { useState, useContext, useEffect } from "react";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle
} from '@material-ui/core';


const Services = () => {
    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <section className="lp-section lp-section-service" id="lp-services">
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                    <div className="lp-section-title">
                        Obtention simplifiée et centralisation de vos documents
                    </div>
                    <div className="lp-section-title-description2">
                        Téléchargez et stockez vos documents et justificatifs indispensables depuis un outil unique et SMART.
                    </div>
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <div className="lp-service-container">
                                <Grid container direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <div className="lp-service-title">
                                            Justificatifs
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className="lp-service-description ">
                                            Téléchargez tous types d’informations et  justificatifs entreprises de <br/> moins de 3 mois, obtention en moins de 3min et 2 clics. <span className="lp-bold">(5/mois)</span>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <img className="lp-service-image" src="/img/lp/service1.svg"></img>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="lp-service-container">
                                <Grid container direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <div className="lp-service-title">
                                        Centralisez vos documents
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className="lp-service-description">
                                        Chargez vos Statuts, Bilans comptables, Rib, ID...pour une disponibilité en 1 <br/> clic et prêts au téléchargement, à la consultation ou l’envoi. <span className="lp-bold">(illimité)</span>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <img className="lp-service-image lp-service-image2" src="/img/lp/service2.svg"></img>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="lp-service-container">
                                <Grid container direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <div className="lp-service-title">
                                        Assistant personnel
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className="lp-service-description">
                                        Pour vous assister, envoyer ou gerer vos dossiers et documents,<br/> disponible par téléphone ou mail - 6/7j et 20/24h.
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <img className="lp-service-image lp-service-image3" src="/img/lp/service3.svg"></img>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="lp-service-container">
                                <Grid container direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <div className="lp-service-title">
                                        Un espace personnalisable
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className="lp-service-description">
                                        Créez un espace personnalisé et évolutif via un système de libellé,<br/> pour une structure, des catégories et des documents spécifiques.
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <img className="lp-service-image lp-service-image4" src="/img/lp/service4.svg"></img>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    )
}
export default Services;