import './App.css';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import Cookies from './components/Cookies';
import RootLayout from './layout/root-layout';
import Redirection from "./Redirection";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import Home from './pages/Home';
import Confirmation from './pages/Confirmation';
import DataContextProvider from "./contexts/DataContext";
import Search from './pages/Search';
import Email from './pages/Email';
import CGU from './pages/CGU';
import AuthContextProvider from './contexts/AuthContext';
import PrivateRoute from './components/wrappers/PrivateRoute';
import Account from './pages/Account';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LegalNotice from './pages/LegalNotics';
import Unsubscribe from './pages/Unsubscribe';
import Contact from './pages/Contact';
import Services from './pages/Services';
import RedirectionForm from './RedirectionForm';
import LP from './pages/LP';
import ScrollToTop from './components/ScrollToTop';
import useAcquisition from "./components/Acquisition";
import RegisterPage from './pages/RegisterPage';
import PaymentTotalProcessing from './pages/PaymentTotalProcessing';
import GoogleRecaptchaContextProvider from './components/recaptcha/GoogleRecaptchaContext';
import Forbidden from './pages/Forbidden';
import PaymentAvis from './pages/PaymentAvis';
import Research from './testpages/Research';
import RootLayout2 from './testpages/root-layout2';

const colors = {
  primary: "#3400C8",
  secondary: "#0DE3B0",
  accent: "#C1272D",
  accent2: "#a4d1fd",
  accent3: "#BFDFFF",
  resumeBG: "#5b91d3"
}

function App() {
  const acquisition = useAcquisition();
  let theme = createMuiTheme({
    palette: {
      primary: {
        light: colors.primary,
        main: colors.primary,
        dark: colors.primary,
        contrastText: '#fff',
      },
      secondary: {
        light: colors.secondary,
        main: colors.secondary,
        dark: colors.secondary,
        contrastText: '#ffffff',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 769,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: 'Livvic',
      fontSize: 14,
      h1: {
        fontSize: 40,
        fontWeight: '600',
        color: '#3400C8',
        lineHeight: 1.2019
      },
      h2: {
        fontSize: 18,
        fontWeight: '400',
        color: '#717171',
        lineHeight: 1.4169
      },
      button: {
        color: '#3400C8',
        fontWeight: '700',
        fontSize: 22,
        lineHeight: 1.4169
      },
      body1: {
        color: '#9FA0A7',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: 1.4169
      },
      body2: {
        color: '#9FA0A7',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: 1.4169
      }
    },
  });

  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <DataContextProvider>
          <Router>
              <ScrollToTop>
                <Switch>
                  <Route path="/recherche">
                    <RootLayout2>
                      <Research></Research>
                    </RootLayout2>
                  </Route>
                  <Route path="/accueil">
                    <RootLayout>
                      <Switch>
                        <PrivateRoute path="/accueil/mon-compte">
                          <Account></Account>
                        </PrivateRoute>
                        <PrivateRoute path="/accueil/confirmation">
                          <Confirmation></Confirmation>
                        </PrivateRoute>
                        <Route path="/accueil/conditions-generales-utilisation">
                          <CGU></CGU>
                        </Route>
                        <Route path="/accueil/politique-de-confidentialite">
                          <PrivacyPolicy></PrivacyPolicy>
                        </Route>
                        <Route path="/accueil/mentions-legales">
                          <LegalNotice></LegalNotice>
                        </Route>
                        <Route path="/accueil/contact">
                          <Contact></Contact>
                        </Route>
                        <Route path="/accueil/desinscription">
                          <Unsubscribe></Unsubscribe>
                        </Route>
                        <Route path="/accueil/services">
                          <Services></Services>
                        </Route>
                        <Route path="/accueil/paiement">
                          <PaymentTotalProcessing></PaymentTotalProcessing>
                        </Route>
                        <Route path="/accueil/avis">
                          <PaymentAvis></PaymentAvis>
                        </Route>
                        <Route path="/accueil/email">
                          <Email></Email>
                        </Route>
                        <Route path="/accueil/recherche">
                          <Search></Search>
                        </Route>
                        <Route path="/accueil">
                          <Home></Home>
                        </Route>
                      </Switch>
                    </RootLayout>
                    <Cookies></Cookies>
                  </Route>
                  <Route path="/inscription">
                    <RegisterPage></RegisterPage>
                  </Route>
                  <Route path="/services">
                    <LP></LP>
                  </Route>
                  <Route path="/demande">
                    <RedirectionForm></RedirectionForm>
                  </Route>
                  <Route path="/forbidden">
                    <Forbidden></Forbidden>
                  </Route>
                  <Route path="/">
                    <LP></LP>
                  </Route>
                </Switch>
              </ScrollToTop>
          </Router>
        </DataContextProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
