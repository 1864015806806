
import { getIP } from "../services/Ipify";

function useAcquisition() {
    const storage = typeof localStorage !== 'undefined' ? localStorage : null;
    if (storage != null) {
        let acquisition = storage.getItem('acquisition');
        if (acquisition) {
            return JSON.parse(acquisition);
        } else {
            var referrer = document.referrer;
            var origin = window.location.href;
            getIP().then(
                (response) => {
                    var ip = response.data;
                    acquisition = { referrer, origin, ip };
                    storage.setItem('acquisition', JSON.stringify(acquisition));
                } 
                , (error) => {
                    console.log(error);
                    acquisition = { referrer, origin };
                    storage.setItem('acquisition', JSON.stringify(acquisition));
                });
            return acquisition;
        }
    }
}

export default useAcquisition;