import { Container } from "@material-ui/core";
import Footer from "../layout/footer";
import HeaderLP from "../lp/headerLP";

const Forbidden = () => {
    return (<div className="page-container">
        <div className="main-with-header lp-main-with-header lp-register-main-content">

            <img src="/img/lp/bg-register-1.svg" className="lp-register-bg-1"></img>
            <img src="/img/lp/bg-register-2.svg" className="lp-register-bg-2"></img>

            {/* 
            <img src={desktop ? "/img/lp/bg-1.svg" : "/img/lp/bg-1-mobile.svg"} className="lp-bg-1"></img>
            <img src="/img/lp/bg-2.svg" className="lp-bg-2"></img>
            */}

            <HeaderLP></HeaderLP>
            <div className="main">
                <Container>
                    <div className="main-content lp-main-content lp-register-main-pannel-container">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", fontSize: "48px", fontWeight: "700", color: "red", height: "100%" }}>
                            FORBIDDEN
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    </div>
    )
}

export default Forbidden;