import React, { useState, useContext, useEffect } from "react";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Container
} from '@material-ui/core';
import RegularInput from "../components/RegularInput";
import NewsletterInput from "../components/NewsletterInput";
import CheckIcon from '@material-ui/icons/Check';


const Newsletter = () => {

    const [formData, setFormData] = useState({
        email: undefined,
        sended: false
    });

    const [loading, setLoading] = useState(false);

    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            setFormData((prevState) => ({
                ...prevState,
                sended: true,
            }));
            setLoading(true);
        }, 2000);

    }
    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <section className=" tool-section">
            <Container>
                <Grid container direction="column" alignItems="center" justify="center">
                    <Grid item>
                        <div className="lp-section-title">
                            Suivez notre actualité et celle du marché
                        </div>
                        <div className="lp-section-title-description-white">
                            Rejoignez notre Newsletter pour rester toujours informé de toutes nos actualités
                        </div>
                    </Grid>
                    <Grid item>
                        <form onSubmit={handleSubmit}>
                            <Grid container direction={desktop ? "row" : "column"} alignItems="center" justify="center" spacing={2}>
                                <Grid item>
                                    <NewsletterInput
                                        placeholder={"Adresse e-mail..."}
                                        value={formData.email}
                                        onChange={setField("email")}
                                        required
                                    ></NewsletterInput>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" justify="center" className="lp-newsletter-button-container">
                                        <Grid item >
                                            {
                                                formData.sended ? <CheckIcon /> :
                                                    loading ? <CircularProgress color="secondary"></CircularProgress> :
                                                        <Button type="submit" variant="outlined" color="secondary" className="lp-newsletter-button">Envoyer</Button>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>

                </Grid>
            </Container>
        </section>
    )
}
export default Newsletter;