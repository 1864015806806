import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import { AuthContext } from "../contexts/AuthContext.js";
import { createKbis } from "../services/FileService";

const CGU = () => {

    const history = useHistory();
    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const desktop = useMediaQuery('(min-width:769px)');
    const { auth } = useContext(AuthContext);

    return (<div>
        <div className="title-generic">
            Conditions générales du site internet www.portail-des-societes.com/.fr/.online
        </div>
        <div style={{ fontStyle: "italic", marginTop: "10px" }}>applicables à compter du 15/09/2021</div>
        <div className="white-container white-container-content">
            <div className="subtitle-generic">Article 1. – PARTIES</div>
            <div className="body-generic">
                Les présentes conditions générales sont applicables entre Oui Lit Ltd, société de droit britannique immatriculée au registre des sociétés de Londres sous le numéro 13584952, siège social : Office 4219 Kensington High Street, Kensington, London W8 6BD, Royaume Uni, téléphone : +44(20)80891400, email : contact@portail-des-societes.com, ci-après « portail-des-sociétés.com/.fr/.online » et toute personne, physique ou morale, de droit privé ou de droit public, inscrite sur le Site, ci-après « le Client ».
            </div>
            <div className="subtitle-generic">Article 2. – DÉFINITIONS</div>
            <div className="body-generic">
                « Abonnement » : droit d’accès temporaire au Service. <br />
                « Base Documentaire » : documents, notamment guides, fiches, vidéos ainsi que la base de données et le logiciel permettant d’accéder à la Base Documentaire.<br />
                « Client » : toute personne, physique ou morale, de droit privé ou de droit public, inscrite sur le Site.<br />
                « Contenus du Site » : éléments de toute nature publiés sur le Site, protégés ou non par un droit de propriété intellectuelle, tel que textes, images, designs, présentations, vidéos, schémas, structures, bases de données ou logiciels. <br />
                « portail-des-sociétés.com/.fr/.online » : Oui Lit ltd pris en sa qualité d’éditeur du Site.<br />
                « Internaute » : toute personne, physique ou morale, de droit privé ou de droit public, se connectant au Site.<br />
                « Licence d’Utilisateur Final » : Licence sous laquelle la Base Documentaire est mise à disposition des Clients par Portail-des-Societes.com.<br />
                « Produit » : bien vendu sur le Site par « portail-des-sociétés.com/.fr/.online » au Client et consistant notamment en tous types d’informations et justificatifs sur les entreprises françaises et immatriculées en France ; tels que Kbis, statuts, bilans comptables...<br />
                « Service » : prestation réalisée par « portail-des-sociétés.com/.fr/.online » au profit du Client et consistant notamment en la mise à disposition en ligne de tous types d’informations et justificatifs sur les entreprises françaises et immatriculées en France.<br />
                « Site » : site internet accessible à l’URL www.portail-des-societes.com/.fr/.online, ainsi que les sous-sites, sites miroirs, portails et variations d’URL y afférents.
            </div>
            <div className="subtitle-generic">Article 3. – CHAMP D’APPLICATION</div>
            <div className="body-generic">
                « portail-des-sociétés.com/.fr/.online » est un service privé, totalement indépendant des administrations publiques et étatiques qui disposent de leurs propres sites internet et services en ligne.<br />
                Le Site est d'accès libre et gratuit à tout Internaute. La navigation sur le Site suppose l'acceptation par tout Internaute des présentes conditions générales. La simple connexion au Site, par quelque moyen que ce soit, notamment par l'intermédiaire d'un robot ou d'un navigateur, emportera acceptation pleine et entière des présentes conditions générales. Lors de l’inscription sur le Site, cette acceptation sera confirmée par le fait de cocher la case correspondante.<br />
                L'Internaute reconnaît du même fait en avoir pris pleinement connaissance et les accepter sans restriction. <br />
                Le fait de cocher la case susvisée sera réputé avoir la même valeur qu'une signature manuscrite de la part de l’Internaute. L'Internaute reconnaît la valeur de preuve des systèmes d'enregistrement automatique de « portail-des-sociétés.com/.fr/.online » et, sauf pour lui d'apporter preuve contraire, il renonce à les contester en cas de litige.<br />
                Les présentes conditions générales sont applicables aux relations entre les parties à l’exclusion de toutes autres conditions, et notamment celles de l’Internaute. <br />
                L'acceptation des présentes conditions générales suppose de la part des Internautes qu'ils jouissent de la capacité juridique nécessaire pour cela, ou à défaut qu'ils en aient l'autorisation d'un tuteur ou d'un curateur s'ils sont incapables, de leur représentant légal s'ils sont mineurs, ou encore qu'ils soient titulaires d'un mandat s'ils agissent pour le compte d'une personne morale.

            </div>



            <div className="subtitle-generic">Article 4. – DESCRIPTION DES SERVICES </div>

            <div className="subtitle-generic2">4.1. Objet du Site</div>
            <div className="body-generic">Le Site a pour objet la mise à disposition du Service par « portail-des-sociétés.com/.fr/.online » au Client.</div>

            <div className="subtitle-generic2">4.2. Services</div>
            <div className="body-generic">Dans le cadre du Service, les Clients ont la possibilité de télécharger sur le Site les Produits mis à sa disposition.</div>

            <div className="subtitle-generic2">4.3. Obligations du Client</div>

            <div className="subtitle-generic3">4.3.1. Déclarations du Client</div>
            <div className="body-generic">Le Client assure avoir pris connaissance, préalablement à la signature des présentes, des caractéristiques techniques du Service. <br />
                En conséquence, le Client reconnaît que ses besoins et les services proposés par « portail-des-sociétés.com/.fr/.online » sont en adéquation, qu’il a connaissance du contenu et des conditions d’exécution desdits Services et qu’il a souscrit aux Services fournis par « portail-des-sociétés.com/.fr/.online » en connaissance de cause et en disposant de toutes les informations nécessaires lui permettant de produire un consentement libre et éclairé.</div>

            <div className="subtitle-generic3">4.3.2. Utilisation des Prestations</div>
            <div className="body-generic">Tout traitement, transmission, publication, diffusion ou représentation des Services par le Client sont effectués sous sa seule et entière responsabilité. Le Client s'engage, en particulier, à ne traiter, diffuser, télécharger, ou transmettre par l'intermédiaire du Services que des données dont l'exploitation ne viole aucun droit de propriété intellectuelle ou industrielle ni tout autre droit privatif, ou ne constitue pas la commission d'une infraction pénale. <br />
                Le Client s'engage également à ne pas entraver ou perturber les Services et les serveurs de « portail-des-sociétés.com/.fr/.online » et à se conformer aux conditions requises, aux procédures, aux règles générales qui lui sont communiquées par « portail-des-sociétés.com/.fr/.online » pour la bonne mise en œuvre du Service. <br />
                Le Client garantit en conséquence « portail-des-sociétés.com/.fr/.online » contre tout dommage susceptible de lui être causé du fait de son utilisation des Services, en ce compris les éventuels frais d’avocat et de procédure, et s’engage à ce titre à intervenir à toute instance judiciaire engagée à son encontre du fait de son utilisation des Services.
            </div>

            <div className="subtitle-generic">Article 5. – ABONNEMENT </div>

            <div className="subtitle-generic2">5.1. Souscription d’un Abonnement</div>
            <div className="body-generic">Afin de profiter du Service, les Clients doivent souscrire un Abonnement. Les caractéristiques de l’Abonnement (notamment son prix et sa durée initiale) sont précisées sur le Site. Les modalités de souscription à l’Abonnement sont indiquées sur le Site, sur le formulaire dédié.</div>

            <div className="subtitle-generic2">5.2. Durée de l’Abonnement</div>
            <div className="body-generic">L’Abonnement prend effet à compter de sa souscription sur le Site. La durée de l’Abonnement est de 30 jours, renouvelable par tacite reconduction. Une fois le premier mois écoulé, chacune des parties pourra résilier le contrat via l’espace personnel, numéro de téléphone ou par email. La résiliation devient effective immédiatement après émission de ladite demande. L'abonnement est sans engagement et résiliable à partir de 4 heures après l'inscription.</div>

            <div className="subtitle-generic2">5.3. Résiliation anticipée de l’Abonnement</div>
            <div className="body-generic">En cas de manquement du Client à l’une des stipulations prévues aux articles 5, 6, et 8 des présentes, « portail-des-sociétés.com/.fr/.online » pourra résilier l’Abonnement. La résiliation de l’Abonnement au tort du Client est sans préjudice des éventuels dommages-intérêts auxquels « portail-des-sociétés.com/.fr/.online » pourrait prétendre du fait du manquement du Client.</div>


            <div className="subtitle-generic">Article 6. – PRIX - PAIEMENT </div>

            <div className="subtitle-generic2">6.1. Prix</div>
            <div className="body-generic">Les prix applicables sont ceux affichés sur le Site au jour de la souscription. Ces prix peuvent être modifiés à tout moment par « portail-des-sociétés.com/.fr/.online » pour toute commande ultérieure. Les prix affichés ne sont valables qu'au jour de la commande et ne portent pas effet pour l'avenir. <br />
                Les prix indiqués sur le Site sont entendus en euros, toutes taxes comprises.
            </div>

            <div className="subtitle-generic2">6.2. Modalité de paiement</div>
            <div className="body-generic">Le Client peut effectuer son règlement par carte bancaire en ligne. Les paiements par carte bancaire se font au moyen de transactions sécurisées. <br />
                Dans le cadre des paiements par carte bancaire, « portail-des-sociétés.com/.fr/.online » n'a accès à aucune donnée relative aux moyens de paiement du Client. Le paiement est effectué directement entre les mains de l'établissement bancaire.
            </div>

            <div className="subtitle-generic2">6.3. Facturation</div>
            <div className="body-generic">« portail-des-sociétés.com/.fr/.online » adressera ou mettra à disposition du Client une facture par voie électronique après chaque paiement. Le Client accepte expressément de recevoir les factures par voie électronique.

            </div>

            <div className="subtitle-generic2">6.4. Défaut de paiement</div>
            <div className="body-generic">Les dates de paiement convenues ne peuvent être retardées sous quelque prétexte que ce soit, y compris en cas de litige. <br />
                Toute somme non payée à l’échéance donnera lieu, de plein droit et sans mise en demeure, à l’application de pénalités de retard calculées sur la base d’un taux égal à 3 fois le taux d’intérêt légal, sans que cette pénalité nuise à l’exigibilité des sommes dues en principal.<br />
                En outre, tout retard de paiement aura pour conséquence la facturation au Client professionnel défaillant de frais de recouvrement d'un montant de 40 euros et l’exigibilité immédiate de toutes les sommes restantes dues quels que soient les délais convenus, majorées d’une indemnité de 20 % du montant à titre de clause pénale.<br />
                En tout état de cause, tout retard de paiement aura pour conséquence la possibilité de résilier le contrat unilatéralement au tort du Client. <br />
                La présente clause s’inscrit dans le cadre des dispositions de l’article 1152 du code civil permettant au juge de réduire l’indemnité si le juge estime qu’elle est excessive.
            </div>


            <div className="subtitle-generic">Article 7. – RECLAMATION - RETRACTATION – GARANTIE </div>

            <div className="subtitle-generic2">7.1. Service clientèle</div>
            <div className="body-generic">Le service clientèle du Site est accessible du dimanche au jeudi de 8h à 20h et le vendredi de 8h à 15h au numéro de téléphone non surtaxé suivant : 01 88 40 56 65, par courrier électronique à : contact@portail-des-societes.com ou via le formulaire de contact mis à disposition sur le site internet. Dans ces deux derniers cas, « portail-des-sociétés.com/.fr/.online » s'engage à apporter une réponse sous 48h ouvrées.
            </div>

            <div className="subtitle-generic2">7.2. Droit de rétractation – Vente à distance</div>
            <div className="body-generic">Le présent article est applicable au Client ayant la qualité de consommateur au sens de l’article liminaire du Code de la consommation.
            </div>

            <div className="subtitle-generic3">7.2.1. Conditions d’exercice du droit de rétractation</div>
            <div className="body-generic">Conformément à la législation en vigueur en matière de vente à distance, le Client dispose d'un délai de quatorze jours francs pour exercer son droit de rétractation sans avoir à justifier de motifs ni à payer de pénalités, à l'exception, le cas échéant, des frais de retour. <br />
                Le délai mentionné à l'alinéa précédent court à compter du jour où le contrat à distance est conclu pour les contrats relatifs à la fourniture d’une prestation de service et/ou à la fourniture d’un contenu numérique non fourni sur un support matériel.<br />
                Lorsque le délai de quatorze jours expire un samedi, un dimanche ou un jour férié ou chômé, il est prorogé jusqu'au premier jour ouvrable suivant.<br />
                La décision de rétractation devra être notifiée à « portail-des-sociétés.com/.fr/.online » aux coordonnées indiquées à l’article 1 des présentes conditions générales au moyen d’une déclaration dénuée d'ambiguïté. Le Client a, par exemple, la possibilité d’utiliser le formulaire-type fourni à la fin des présentes conditions générales. En tout état de cause, « portail-des-sociétés.com/.fr/.online » adressera au Client au plus tôt un accusé de réception de ladite rétractation par courrier électronique.

            </div>

            <div className="subtitle-generic3">7.2.2. Effets du droit de rétractation</div>
            <div className="body-generic">Lorsque le droit de rétractation est exercé, le professionnel est tenu de rembourser le Client de la totalité des sommes versées, dans les meilleurs délais et au plus tard dans les quatorze jours suivant la date à laquelle ce droit a été exercé. Au-delà, la somme due est, de plein droit, productive d'intérêts au taux légal en vigueur, tel que précisé à l’article L. 242-4 du Code de la consommation. <br />
                Le cas échéant, le professionnel effectue le remboursement en utilisant le même moyen de paiement que celui utilisé par le Client pour la transaction initiale, sauf accord exprès du Client pour l’utilisation d’un autre moyen de paiement et dans la mesure où le remboursement n'occasionne pas de frais pour le Client. <br />
                Les conditions, délais et modalités d’exercice du droit de rétractation sont exposés dans le formulaire-type fourni à la fin des présentes conditions générales.
            </div>

            <div className="subtitle-generic3">7.2.3. Exclusions du droit de rétractation</div>
            <div className="body-generic">Le droit de rétractation ne s’applique pas, notamment, aux contrats : <br />
                - de fourniture de services pleinement exécutés avant la fin du délai de rétractation et dont l'exécution a commencé après accord préalable exprès du Client et renoncement exprès à son droit de rétractation ; <br />
                - de fourniture d'un contenu numérique non fourni sur un support matériel dont l'exécution a commencé après accord préalable exprès du Client et renoncement exprès à son droit de rétractation.<br />
                De même, le droit de rétractation n’est pas applicable aux contrats exécutés intégralement par les deux parties à la demande expresse du Client avant que ce dernier n'exerce son droit de rétractation.<br />
                Lors de la validation de la commande d’un contenu numérique indépendant de tout support matériel avant l'expiration du délai de rétractation, le renoncement du Client au droit de rétractation sera manifesté par le fait de cocher la case correspondant à la phrase suivante : « Je renonce expressément à mon droit de rétractation de 14 jours pour les Produits livrés ». Le Client reçoit alors une confirmation par email de son renoncement au droit de rétractation.<br />
                Lors de la validation de la commande d’une prestation, le renoncement du Client au droit de rétractation sera manifesté par le fait de cocher la case correspondant à la phrase suivante : « Je renonce expressément à mon droit de rétractation de 14 jours pour les prestations dont je bénéficierais avant l'écoulement de ce délai ». Le Client reçoit alors une confirmation par email de son renoncement au droit de rétractation.<br />
                Le Client qui a exercé son droit de rétractation d'un contrat de prestation de services dont l'exécution a commencé, à sa demande expresse, avant la fin du délai de rétractation verse au professionnel un montant correspondant au service fourni jusqu'à la communication de sa décision de se rétracter, ce montant étant proportionnel au prix total de la prestation convenu dans le contrat.
            </div>

            <div className="subtitle-generic">Article 8. – ESPACE PERSONNEL </div>


            <div className="subtitle-generic2">8.1. Création de l’espace personnel</div>
            <div className="body-generic">La création d'un espace personnel est un préalable indispensable à toute commande d'un Internaute sur le Site. A cette fin, l’Internaute sera invité à fournir un certain nombre d'informations personnelles. Certaines de ces informations sont réputées indispensables à la création de l'espace personnel. Le refus par un Internaute de fournir lesdites informations aura pour effet d'empêcher la création de l'espace personnel ainsi que, incidemment, la validation de la commande. <br />
                Lors de la création de l'espace personnel, l'Internaute est invité à choisir un mot de passe. Ce mot de passe constitue la garantie de la confidentialité des informations contenues dans l’espace personnel. L'Internaute s'interdit donc de le transmettre ou de le communiquer à un tiers. A défaut, « portail-des-sociétés.com/.fr/.online » ne pourra être tenu pour responsable des accès non autorisés à l’espace personnel d'un Internaute.<br />
                Le Client s’engage à procéder à une vérification régulière des données qui le concernent et à procéder en ligne, depuis son espace personnel, aux actualisations et modifications nécessaires.

            </div>

            <div className="subtitle-generic2">8.2. Contenu de l’espace personnel</div>
            <div className="body-generic">L’espace personnel permet au Client de stocker et centraliser ses documents professionnels, de consulter et de suivre toutes ses commandes effectuées sur le Site. <br />
                Les pages relatives aux espaces personnels sont librement imprimables par le titulaire du compte en question, mais ne constituent nullement une preuve admissible par un tribunal. Elles n'ont qu'un caractère informatif destiné à assurer une gestion efficace de ses commandes par le Client.<br />
                « portail-des-sociétés.com/.fr/.online » s'engage à conserver de façon sécurisée tous les éléments contractuels dont la conservation est requise par la loi ou la réglementation en vigueur.

            </div>

            <div className="subtitle-generic2">8.3. Suppression de l’espace personnel</div>
            <div className="body-generic">« portail-des-sociétés.com/.fr/.online » se réserve le droit de supprimer le compte de tout Client qui contrevient aux présentes conditions générales, notamment lorsque le Client fournit des informations inexactes, incomplètes, mensongères ou frauduleuses, ainsi que lorsque l’espace personnel d’un Client sera resté inactif depuis au moins une année. Ladite suppression ne sera pas susceptible de constituer une faute de « portail-des-sociétés.com/.fr/.online » ou un dommage pour le Client exclu, qui ne pourra prétendre à aucune indemnité de ce fait. <br />
                Cette exclusion est sans préjudice de la possibilité, pour « portail-des-sociétés.com/.fr/.online », d'entreprendre des poursuites d'ordre judiciaire à l'encontre du Client, lorsque les faits l'auront justifié.

            </div>


            <div className="subtitle-generic">Article 9. – DONNÉES PERSONNELLES </div>
            <div className="body-generic">Dans le cadre de sa prestation, « portail-des-sociétés.com/.fr/.online » va être amené à traiter des données à caractère personnel de ses Clients.
            </div>

            <div className="subtitle-generic2">9.1. Identité du responsable du traitement</div>
            <div className="body-generic">Le responsable de la collecte et des données traitées sur le Site est « portail-des-sociétés.com/.fr/.online ».

            </div>

            <div className="subtitle-generic2">9.2. Identité du Délégué à la protection des données</div>
            <div className="body-generic">Afin de veiller au respect de la bonne application du RGPD, « portail-des-sociétés.com/.fr/.online » a désigné et nommé un prestataire externe en tant que DPO « Data Protection Officer ».

            </div>

            <div className="subtitle-generic2">9.3. Données collectées</div>

            <div className="subtitle-generic3">9.3.1. Données collectées auprès des clients</div>
            <div className="body-generic">Dans le cadre de ses relations contractuelles, « portail-des-sociétés.com/.fr/.online » peut être amené à collecter et traiter des informations de ses Clients, à savoir : les noms, prénoms, fonction, nom de la société, numéro de téléphone, adresses postales, adresses mails, historique des contrats.
            </div>

            <div className="subtitle-generic3">9.3.2. Finalités de la collecte de données personnelles</div>
            <div className="body-generic">Les données collectées lors de la relation contractuelle font l'objet d'un traitement automatisé ayant pour finalité de :
            </div>

            <div className="body-generic body-generic-pad" >
                - exécuter les engagements contractuels ;<br />
                - contacter les Clients ;<br />
                éviter toute activité illicite ou illégale ;<br />
                faire respecter les conditions générales ;<br />
                engager des procédures judiciaires ;<br />
                vérifier l'identité des Clients ;
            </div>

            <div className="subtitle-generic3">9.3.3. Bases juridiques du traitement</div>
            <div className="body-generic">Les données collectées ont pour base juridique une relation contractuelle.
            </div>

            <div className="subtitle-generic3">9.3.4. Destinataires des données</div>
            <div className="body-generic">Les données collectées sont consultables uniquement par « portail-des-sociétés.com/.fr/.online » dans les limites strictement nécessaires à l’exécution des engagements contractuels. <br />
                Ces données, que ce soit sous forme individuelle ou agrégée, ne sont jamais rendues librement visualisables par une personne physique tierce.
            </div>

            <div className="subtitle-generic3">9.3.5. Durée de conservation des données personnelles</div>
            <div className="body-generic">Les données personnelles collectées sont conservées pendant le temps de la relation contractuelle, et pendant le temps durant lequel la responsabilité de « portail-des-sociétés.com/.fr/.online » peut être engagée.  <br />
                Passé le délai de conservation, « portail-des-sociétés.com/.fr/.online » s’engage à supprimer définitivement les données des personnes concernées sans en conserver une copie.

            </div>

            <div className="subtitle-generic3">9.3.6. Sécurité et confidentialité des données personnelles</div>
            <div className="body-generic">Les données personnelles sont conservées dans des conditions sécurisées, selon les moyens actuels de la technique, dans le respect des dispositions du Règlement général sur la protection des données et de la législation nationale en vigueur. <br />
                L’accès aux locaux de « portail-des-sociétés.com/.fr/.online » est également sécurisé.

            </div>

            <div className="subtitle-generic3">9.3.7. Minimisation des données</div>
            <div className="body-generic">« portail-des-sociétés.com/.fr/.online » peut également collecter et traiter toute donnée transmise volontairement par ses Clients. <br />
                « portail-des-sociétés.com/.fr/.online » oriente ses Clients afin qu’il fournisse des données à caractère personnel strictement nécessaires à l’exécution des engagements contractuels. <br />
                « portail-des-sociétés.com/.fr/.online » s’engage à ne conserver et traiter que les données strictement nécessaires à ses activités professionnelles, et supprimera toute donnée reçue non utile à ses activités dans les plus brefs délais.

            </div>


            <div className="subtitle-generic2">9.4. Respect des droits</div>
            <div className="body-generic">Les Clients de « portail-des-sociétés.com/.fr/.online » disposent des droits suivants concernant leurs données personnelles, qu’ils peuvent exercer en écrivant à l’adresse de contact : contact@portail-des-societes.com.

            </div>

            <div className="subtitle-generic3">9.4.1. Droit d’information, d’accès et de communication des données</div>
            <div className="body-generic">Les Clients de « portail-des-sociétés.com/.fr/.online » ont la possibilité d’accéder aux données personnelles qui les concernent. <br />
                En raison de l’obligation de sécurité et de confidentialité dans le traitement des données à caractère personnel qui incombe à « portail-des-sociétés.com/.fr/.online », les demandes seront uniquement traitées si les Clients rapportent la preuve de leur identité, notamment par la production d’un scan de leur titre d’identité valide (en cas de demande par le formulaire électronique dédié) ou d’une photocopie signée de leur titre d’identité valide (en cas de demande adressée par écrit), tous deux accompagnés de la mention « j’atteste sur l’honneur que la copie de cette pièce d’identité est conforme à l’original. Fait à … le … », suivie de leur signature.<br />
                Pour les aider dans leur démarche, les Clients trouveront <a href="https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces"> ici</a> un modèle de courrier élaboré par la Cnil.

            </div>

            <div className="subtitle-generic3">9.4.2. Droit de rectification, de suppression et droit à l’oubli des données</div>
            <div className="body-generic">Les Clients de « portail-des-sociétés.com/.fr/.online » ont la possibilité de demander la rectification, la mise à jour, le verrouillage ou encore l’effacement de leurs données personnelles qui peuvent s’avérer le cas échéant inexactes, erronées, incomplètes ou obsolètes. <br />
                Les Clients de « portail-des-sociétés.com/.fr/.online » peuvent également définir des directives générales et particulières relatives au sort des données à caractère personnel après leur décès. Le cas échéant, les héritiers d’une personne décédée peuvent exiger de prendre en considération le décès de leur proche et/ou de procéder aux mises à jour nécessaires.<br />
                Pour les aider dans leur démarche, les Clients trouveront  <a href="https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees"> ici</a> un modèle de courrier élaboré par la Cnil.


            </div>

            <div className="subtitle-generic3">9.4.3. Droit d’opposition au traitement de données</div>
            <div className="body-generic">Les Clients de « portail-des-sociétés.com/.fr/.online » ont la possibilité de s’opposer à un traitement de leurs données personnelles. <br />
                Pour les aider dans leur démarche, les Clients trouveront  <a href="https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees"> ici</a> un modèle de courrier élaboré par la Cnil.
            </div>

            <div className="subtitle-generic3">9.4.4. Droit à la portabilité des données</div>
            <div className="body-generic">Les Clients de « portail-des-sociétés.com/.fr/.online » ont le droit de recevoir les données personnelles qu’ils ont fournies à « portail-des-sociétés.com/.fr/.online » dans un format transférable, ouvert et lisible.
            </div>

            <div className="subtitle-generic3">9.4.5. Droit à la limitation du traitement</div>
            <div className="body-generic">Les Clients de « portail-des-sociétés.com/.fr/.online » ont le droit de demander que le traitement de leurs données personnelles par « portail-des-sociétés.com/.fr/.online » soit limité. Ainsi, leurs données ne pourront qu’être conservées et non plus utilisées par « portail-des-sociétés.com/.fr/.online ».
            </div>

            <div className="subtitle-generic3">9.4.6. Délais de réponse </div>
            <div className="body-generic">« portail-des-sociétés.com/.fr/.online » s’engage à répondre à toute demande d’accès, de rectification ou d’opposition ou toute autre demande complémentaire d’informations dans un délai raisonnable qui ne saurait dépasser 1 mois à compter de la réception de la demande.
            </div>

            <div className="subtitle-generic3">9.4.7. Plainte auprès de l’autorité compétente </div>
            <div className="body-generic">Si les Clients de « portail-des-sociétés.com/.fr/.online » considèrent que « portail-des-sociétés.com/.fr/.online » ne respecte pas ses obligations au regard de leurs données à caractère personnel, ils peuvent adresser une plainte ou une demande auprès de l’autorité compétente. En France, l’autorité compétente est la Cnil à laquelle ils peuvent adresser une demande  <a href="https://www.cnil.fr/fr/plaintes/internet"> ici</a>.

            </div>


            <div className="subtitle-generic2">9.5. Transfert des données collectées </div>
            <div className="body-generic">Les Clients de « portail-des-sociétés.com/.fr/.online » disposent des droits suivants concernant leurs données personnelles, qu’ils peuvent exercer en écrivant à l’adresse de contact : contact@portail-des-societes.com.

            </div>

            <div className="subtitle-generic3">9.5.1. Transfert à des partenaires  </div>
            <div className="body-generic">« portail-des-sociétés.com/.fr/.online » a recours à des prestataires habilités pour faciliter la collecte et le traitement des données de ses Clients. Ces prestataires peuvent être situés en dehors de l’Union Européenne. <br />
                « portail-des-sociétés.com/.fr/.online » s’est préalablement assuré de la mise en œuvre par ses prestataires de garanties adéquates et du respect de conditions strictes en matière de confidentialité, d’usage et de protection des données, par exemple via le Privacy Shield états-unien.
            </div>

            <div className="subtitle-generic3">9.5.2. Transfert sur réquisition ou décision judiciaire  </div>
            <div className="body-generic">Les Clients consentent également à ce que « portail-des-sociétés.com/.fr/.online » communique les données collectées à toute personne, sur réquisition d’une autorité étatique ou sur décision judiciaire.
            </div>

            <div className="subtitle-generic3">9.5.3. Transfert dans le cadre d'une fusion ou d'une acquisition  </div>
            <div className="body-generic">Si « portail-des-sociétés.com/.fr/.online » est impliqué dans une fusion, une vente d'actifs, une opération de financement, une liquidation ou banqueroute ou dans une acquisition de tout ou partie de son activité par une autre société, les Clients consentent à ce que les données collectées soient transmises par « portail-des-sociétés.com/.fr/.online » à cette société et que cette société opère les traitements de données personnelles visés dans les présentes Conditions générales de service au lieu et place de « portail-des-sociétés.com/.fr/.online ».

            </div>

            <div className="subtitle-generic">Article 10. – RESPONSABILITE DE portail-des-sociétés.com/.fr/.online </div>

            <div className="subtitle-generic2">10.1. Nature des obligations de portail-des-sociétés.com/.fr/.online </div>

            <div className="subtitle-generic3">10.1.1. Fourniture des Services </div>
            <div className="body-generic">« portail-des-sociétés.com/.fr/.online » s'engage à apporter le soin et la diligence nécessaire à la fourniture de Services de qualité conforme aux spécifications des présentes Conditions générales. « portail-des-sociétés.com/.fr/.online » ne répond que d'une obligation de moyens concernant les services objet des présentes.

            </div>

            <div className="subtitle-generic3">10.1.2. Accès à la Base Documentaire </div>
            <div className="body-generic">« portail-des-sociétés.com/.fr/.online » n'est tenu à aucune garantie particulière concernant le contenu de la Base Documentaire, quels que soient la rubrique ou le Service concerné, leur adéquation par rapport à la recherche effectuée par le Client, leur existence et leur accessibilité par le biais du Service. « portail-des-sociétés.com/.fr/.online » pourra faire librement évoluer sa Base Documentaire, le Client n’ayant pas de droit au maintien de la Base Documentaire dans l’état où elle se trouvait au moment de la souscription au Service. <br />
                Le Client est seul responsable du choix du Produit, du choix des informations qu'il sélectionne, de leur conformité à l'utilisation à laquelle il les destine, ainsi que de l'usage qu'il en fait. L’utilisation de la Base Documentaire se fait sous la seule responsabilité du Client.

            </div>

            <div className="subtitle-generic2">10.2. Force majeure - Faute du Client </div>
            <div className="body-generic">« portail-des-sociétés.com/.fr/.online » n'engagera pas sa responsabilité en cas de force majeure ou de faute du Client, telles que définies au présent article :
            </div>

            <div className="subtitle-generic3">10.2.1. Force majeure </div>
            <div className="body-generic">Au sens des présentes conditions générales, sera considéré comme un cas de force majeure opposable au Client tout empêchement, limitation ou dérangement du Service du fait d'incendie, d'épidémie, d'explosion, de tremblement de terre, de fluctuations de la bande passante, de manquement imputable au fournisseur d'accès, de défaillance des réseaux de transmission, d'effondrement des installations, d'utilisation illicite ou frauduleuse des mots de passe, codes ou références fournis au Client, de piratage informatique, d'une faille de sécurité imputable à l'hébergeur du Site ou aux développeurs, d'inondation, de panne d'électricité, de guerre, d'embargo, de loi, d'injonction, de demande ou d'exigence de tout gouvernement, de réquisition, de grève, de boycott, ou autres circonstances hors du contrôle raisonnable de « portail-des-sociétés.com/.fr/.online ». Dans de telles circonstances, « portail-des-sociétés.com/.fr/.online » sera dispensé de l'exécution de ses obligations dans la limite de cet empêchement, de cette limitation ou de ce dérangement.
            </div>

            <div className="subtitle-generic3">10.2.2. Faute du Client </div>
            <div className="body-generic">Au sens des présentes Conditions générales, sera considéré comme une faute du Client opposable à ce dernier toute mauvaise utilisation du Service, faute, négligence, omission ou défaillance de sa part ou de celle de ses préposés, non-respect des conseils donnés par « portail-des-sociétés.com/.fr/.online » sur son Site, toute divulgation ou utilisation illicite du mot de passe, des codes et références du Client, ainsi que le renseignement d’informations erronées ou l’absence de mise à jour de telles informations dans son espace personnel. Sera également considérée comme une faute du Client la mise en œuvre de tout procédé technique, tels que des robots, ou des requêtes automatiques, dont la mise en œuvre contreviendrait à la lettre ou à l’esprit des présentes conditions générales de vente.
            </div>

            <div className="subtitle-generic2">10.3. Problèmes techniques - Liens hypertextes</div>
            <div className="body-generic">En cas d'impossibilité d'accès au Site, en raison de problèmes techniques de toutes natures, le Client ne pourra se prévaloir d'un dommage et ne pourra prétendre à aucune indemnité. L'indisponibilité, même prolongée et sans aucune durée limitative, d'un ou plusieurs services en ligne, ne peut être constitutive d'un préjudice pour les Clients et ne peut aucunement donner lieu à l'octroi de dommages et intérêts de la part de « portail-des-sociétés.com/.fr/.online ». <br />
                Les liens hypertextes présents sur le Site peuvent renvoyer sur d'autres sites internet. La responsabilité de « portail-des-sociétés.com/.fr/.online » ne saurait être engagée si le contenu de ces sites contrevient aux législations en vigueur. De même, la responsabilité de « portail-des-sociétés.com/.fr/.online » ne saurait être engagée si la visite, par l'Internaute, de l'un de ces sites, lui causait un préjudice. </div>


            <div className="subtitle-generic2">10.4. Dommages-intérêts à la charge de portail-des-sociétés.com/.fr/.online</div>
            <div className="body-generic">A défaut de dispositions légales ou réglementaires contraires, la responsabilité de « portail-des-sociétés.com/.fr/.online » est limitée au préjudice direct, personnel et certain subi par le Client et lié à la défaillance en cause. « portail-des-sociétés.com/.fr/.online » ne pourra en aucun cas être tenu responsable des dommages indirects tels que, notamment les pertes de données, les préjudices commerciaux, les pertes de commandes, les atteintes à l'image de marque, les troubles commerciaux et les pertes de bénéfices ou de clients. De même et dans les mêmes limites, le montant des dommages et intérêts mis à la charge de « portail-des-sociétés.com/.fr/.online » ne pourra en tout état de cause excéder le prix du Service commandé.
            </div>

            <div className="subtitle-generic2">10.5. Liens hypertextes et contenus du Site</div>
            <div className="body-generic">Les Contenus du Site sont publiés à titre indicatif, sans garantie d'exactitude. « portail-des-sociétés.com/.fr/.online » ne peut en aucun cas être tenu responsable du fait d'une omission, d'une inexactitude ou de toute erreur contenue dans ces informations et qui serait à l'origine d'un dommage direct ou indirect causé à l'Internaute.
            </div>


            <div className="subtitle-generic">Article 11. – PROPRIÉTÉ INTELLECTUELLE  </div>

            <div className="subtitle-generic2">11.1. Protection légale des Contenus du Site</div>
            <div className="body-generic">Les Contenus du Site sont susceptibles d'être protégés par le droit d'auteur et le droit des bases de données. Toute représentation, reproduction, traduction, adaptation ou transformation, intégrale ou partielle, réalisée illégalement et sans le consentement de « portail-des-sociétés.com/.fr/.online » ou de ses ayants droit ou ayants cause constitue une violation des Livres I et III du Code de la propriété intellectuelle et sera susceptible de donner lieu à des poursuites judiciaires pour contrefaçon.

            </div>

            <div className="subtitle-generic2">11.2. Protection contractuelle des Contenus du Site</div>
            <div className="body-generic">L'Internaute s'engage contractuellement à l'égard de « portail-des-sociétés.com/.fr/.online » à ne pas utiliser, reproduire ou représenter, de quelque manière que ce soit, les Contenus du Site, qu'ils soient ou non protégés par un droit de propriété intellectuelle, à une autre fin que celle de leur lecture par un robot ou un navigateur. Cette interdiction n'est pas applicable aux robots d'indexation ayant pour seul objet de scanner le contenu du Site aux fins d'indexation.

            </div>

            <div className="subtitle-generic2">11.3. Accès à la Base Documentaire</div>

            <div className="subtitle-generic3">11.3.1. Titularité de portail-des-sociétés.com/.fr/.online sur la Base Documentaire </div>
            <div className="body-generic">« portail-des-sociétés.com/.fr/.online » est et demeure titulaire des droits de propriété relatifs à la Base Documentaire mise à disposition du Client. <br />
                Le présent contrat ne confère au Client aucun droit de propriété sur la Base Documentaire, à l’exclusion d’un droit d’utilisation personnel et non cessible à titre d’utilisateur final. La mise à disposition temporaire de la Base Documentaire dans les conditions prévues aux présentes conditions générales ne saurait être analysée comme la cession d’un quelconque droit de propriété intellectuelle au bénéfice du Client.
            </div>

            <div className="subtitle-generic3">11.3.2. Licence d’Utilisateur Final </div>
            <div className="body-generic">« portail-des-sociétés.com/.fr/.online » consent au Client, à titre non exclusif, une licence d’utilisateur final portant sur la Base Documentaire. La licence est consentie pour le monde entier et pour toutes langues. <br />
                La licence porte uniquement sur :

            </div>
            <div className="body-generic body-generic-pad" >
                • le droit d’utilisation des documents contenus dans la Base Documentaire, aux seules fins de télécharger lesdits documents sur l’ordinateur personnel du Client et de les imprimer, à l’exclusion de : <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- toute reproduction en vue de la commercialisation, de la mise en circulation, de la rediffusion, de la distribution ou de la publication desdits documents ;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- toute représentation, même partielle, desdits documents, notamment par voie de communications au public en ligne ou de projections publiques ;<br />
                • le droit d’utilisation à distance de la base de données et du logiciel mis en œuvre pour le fonctionnement de la Base Documentaire, à l’exclusion de :<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- toute reproduction de façon permanente ou provisoire de la base de données et du logiciel, en tout ou partie, par tout moyen et sous toute forme, y compris à l'occasion du chargement, de l'affichage et de l'exécution ;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- toute représentation, même partielle, de la base de données et du logiciel, notamment par voie de communications au public en ligne ou de projections publiques ;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- toute traduction, adaptation, d'arrangement ou de modification de la base de données et du logiciel, ainsi que tout export ou fusion de ce dernier avec d'autres applications informatiques.

            </div>
            <div className="body-generic">Les droits d’utilisation de la Base Documentaire sont personnels et non cessibles. Les droits accordés au Client le sont exclusivement pour un utilisateur unique, à l'intérieur de ses locaux et pour ses besoins propres, à l'exclusion de toute forme de fourniture à des tiers, même à une société filiale ou un membre d'un groupement de droit ou de fait. <br />
                Le Client ne pourra céder tout ou partie des droits et obligations résultant du présent contrat, que ce soit dans le cadre d'une cession temporaire, d'une sous-licence et de tout autre contrat prévoyant le transfert desdits droits et obligations.


            </div>

            <div className="subtitle-generic3">11.3.3. Garantie de la Base Documentaire </div>
            <div className="body-generic">Le Client accepte la présente licence en l’état. « portail-des-sociétés.com/.fr/.online » ne donne pas d’autre garantie que celle de l’existence matérielle des droits objets de la présente licence.
            </div>

            <div className="subtitle-generic">Article 12. – STIPULATIONS FINALES  </div>

            <div className="subtitle-generic2">12.1. Droit applicable </div>
            <div className="body-generic">Les présentes conditions générales sont soumises à l'application du droit français.

            </div>

            <div className="subtitle-generic2">12.2. Modifications des présentes conditions générales </div>
            <div className="body-generic">Les présentes conditions générales peuvent être modifiées à tout moment par « portail-des-sociétés.com/.fr/.online ». Les conditions générales applicables au Client sont celles en vigueur au jour de sa commande ou de sa connexion sur le présent Site, toute nouvelle connexion à l'espace personnel emportant acceptation le cas échéant des nouvelles conditions générales.

            </div>

            <div className="subtitle-generic2">12.3. Litiges </div>
            <div className="body-generic">
            En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous litiges qui pourraient survenir dans le cadre de l'exécution des présentes conditions générales et dont la solution n’aura pu être trouvée préalablement à l’amiable entre les parties devra être soumis à CM2C : <a href="https://www.cm2c.net"> https://www.cm2c.net</a>  <br/>
            Lien vers la plateforme de règlement en ligne des litiges (RLL) : <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=FR" >https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=FR</a>
            </div>



            <div className="subtitle-generic2">12.4. Entièreté </div>
            <div className="body-generic">La nullité d'une des clauses du présent contrat n'entraînera pas la nullité des autres clauses du contrat ou du contrat dans sa globalité, qui garderont leur plein effet et portée. Dans une telle hypothèse, les parties devront dans la mesure du possible remplacer la stipulation annulée par une stipulation valable correspondant à l'esprit et à l'objet des présentes.
            </div>

            <div className="subtitle-generic2">12.5. Non-renonciation </div>
            <div className="body-generic">L'absence d'exercice par « portail-des-sociétés.com/.fr/.online » des droits qui lui sont reconnus par les présentes ne pourra en aucun cas être interprétée comme une renonciation à faire valoir lesdits droits.
            </div>

            <div className="subtitle-generic2">12.6. Démarchage téléphonique </div>
            <div className="body-generic">Le Client est informé qu’il a la possibilité de s’inscrire sur la liste d'opposition au démarchage téléphonique à l’adresse http://www.bloctel.gouv.fr/.  </div>

            <div className="subtitle-generic2">12.7. Langues des présentes conditions générales </div>
            <div className="body-generic">Les présentes conditions générales sont proposées en français.
            </div>

            <div className="subtitle-generic2">12.8. Clauses abusives </div>
            <div className="body-generic">Les stipulations des présentes conditions générales s'appliquent sous réserve du respect des dispositions impératives du Code de la consommation concernant les clauses abusives dans les contrats conclus entre un professionnel et un consommateur.
            </div>

        </div>
    </div>
    )
}
export default CGU;