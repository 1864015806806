import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import { AuthContext } from "../contexts/AuthContext.js";
import { createKbis } from "../services/FileService";

const Contact = () => {

    const history = useHistory();
    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const desktop = useMediaQuery('(min-width:769px)');
    const { auth } = useContext(AuthContext);

    return (<div>
        <div className="title-generic">
            Contact
        </div>
        <div className="white-container white-container-content">
            <div className="subtitle-generic">Réponse sous 24h</div>
            <div className="body-generic">
                Nous nous engageons à vous répondre sous 24h pour toute demande sur cet email : contact@portail-des-societes.com<br></br>
                Vous pouvez aussi nous joindre directement par téléphone du dimanche au jeudi de 8h à 20h et le vendredi de 8h à 15h pour les demandes urgentes au : 01 88 40 56 65 <br></br>
            </div>



        </div>
    </div>
    )
}
export default Contact;