import { Box, Button, CircularProgress, Collapse, FormControlLabel, Grid, Snackbar, useMediaQuery } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { getCheckoutId, getNewCardCheckoutId, proceedNewCard, proceedPayment } from "./PaymentTotalProcessingService";
import './totalProcessing-newcard.css';
import $ from 'jquery';
import MainCheckbox from "../MainCheckbox";
import { useHistory, useLocation } from "react-router-dom";
import { DataContext } from "../../contexts/DataContext";
import useAcquisition from "../Acquisition";
import { AuthContext } from "../../contexts/AuthContext";
import queryString from 'query-string';

import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NewCardTotalProcessing = (props) => {

    const [checkoutId, setCheckoutId] = useState(null);
    const history = useHistory();
    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const { search } = useLocation();
    const acquisition = useAcquisition();
    const { auth, authMethods } = useContext(AuthContext);
    const [paymentError, setPaymentError] = useState(false);

    const [message, setMessage] = useState("");
    const [type, setType] = useState("success");
    const [open, setOpen] = useState(false);

    
    function handleCloseSnackBar(e) {
        setOpen(false);
    }

    const notifySuccess = (snackMessage) => {
        setMessage(snackMessage);
        setType("success");
        setOpen(true);
    }

    const notifyError = (snackMessage) => {
        setMessage(snackMessage);
        setType("error");
        setOpen(true);
    }

    function setConfigurationPaymentForm() {
        window.wpwlOptions = {
            locale: "fr",
            brandDetection: true,
            brandDetectionType: "binlist",
            onReady: function () {
                $(".wpwl-group-cardHolder").before($(".wpwl-group-expiry"));
                $(".wpwl-group-cardNumber").after($(".wpwl-group-cardHolder"));
                $('.wpwl-label-cardNumber').html('Entrez votre numéro de carte');
                $('.wpwl-label-expiry').html('Date de péremption');
                $('.wpwl-label-cvv').html('CVC');
                $('.wpwl-control-iframe.wpwl-control-cardNumber').attr("placeholder", "Numéro de carte");
                $('.wpwl-control.wpwl-control-expiry').attr("placeholder", "MM / AA");
                $('.wpwl-control-iframe.wpwl-control-cvv').attr("placeholder", "CVC");
                $('.wpwl-button-pay').html('<span class="MuiButton-label"><div class="MuiGrid-root MuiGrid-container MuiGrid-align-items-xs-center MuiGrid-justify-content-xs-space-between"><div class="MuiGrid-root MuiGrid-item"></div><div class="MuiGrid-root MuiGrid-item">Valider</div><img src="/img/arrow-right2.svg"></div></span><span class="MuiTouchRipple-root"></span>');
            },
            onBeforeSubmitCard: preCheck
        };
    }


    function validateHolder() {
        var holder = $('.wpwl-control-cardHolder').val();
        if (holder.trim().length < 2) {
            $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">Invalid card holder</div>');
            return false;
        }
        return true;
    }

    function preCheck() {
        setOpenError(false);
        setLoading(true);
        if (!validateHolder()) {
            setLoading(false);
            setLoadingDialog(false);
            setError("Le nom du titulaire de la carte est obligatoire.");
            setOpenError(true);
            return false;
        }
        return true;
    }

    async function prepareCheckout() {
        var checkoutInfo = await getNewCardCheckoutId(auth);
        if (checkoutInfo.existAndIsSubscribed) {
            setError("Un compte actif existe déjà avec cet email. Connectez-vous puis allez sur l'onglet 'Nouveau KBIS' pour générer un nouveau KBIS.");
            setOpenError(true);
        } else {
            if (checkoutInfo.id != undefined && checkoutInfo.id != null) {
                setCheckoutId(checkoutInfo.id);
            }
        }
    }

    useEffect(() => {

        async function handlePaymentProcess(paymentId) {
            console.log("Handling payment process")
            setPaymentError(false);
            await proceedNewCard(auth, acquisition.origin, acquisition.referrer, acquisition.ip, paymentId)
                .then((data) => {
                    history.push("/accueil/mon-compte/details");
                    props.setChangeCard(false);
                    notifySuccess("Votre moyen de paiement a été mis à jour")
                })
                .catch((error) => {
                    setLoadingDialog(false);
                    props.setBlurInfos(false);
                    setLoading(false);
                    if (error.response.status == 403 && error.response.data.response != undefined && error.response.data.response == "Fraud") {
                        setError("Banque bloquée suite à des fraudes à répétition. Des procédures sont en cours, veuillez nous contacter pour plus d'informations.");
                    } else {
                        setError("Une erreur s'est produite.");
                    }
                    setOpenError(true);
                    setPaymentError(true);

                });
        }

        const values = queryString.parse(search)
        // Back from Total Processing payment
        if (values.id == null) {
            setConfigurationPaymentForm();
            prepareCheckout();
            window.acceptedTerms = false;
        } else {
            handlePaymentProcess(values.id);
        }
    }, []);


    const [loading, setLoading] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [error, setError] = useState("");
    const [openError, setOpenError] = useState(false);

    return (
        <div>
            <Helmet>
                {checkoutId && <script src={(process.env.NODE_ENV === "production" ? 'https://eu-prod.oppwa.com/' : 'https://eu-test.oppwa.com/') + `v1/paymentWidgets.js?checkoutId=${checkoutId}`}></script>}
            </Helmet>
            <Collapse in={openError}>
                <Alert
                    style={{ marginBottom: "15px" }}
                    severity="error"
                    action={
                        <i
                            className="fas fa-times"
                            style={{ color: "inherit", cursor: "pointer" }}
                            onClick={() => setOpenError(false)}
                        ></i>
                    }
                >
                    {error}
                </Alert>
            </Collapse>
            {
                paymentError &&
                <Button variant="contained" color="primary" className="cta" onClick={() => {
                    history.push("/accueil/paiement")
                    history.go(0)
                }}>Réessayer</Button>
            }
            {checkoutId && <>
                <Grid container direction="row" spacing={1} className="display-mobile card-icon-div-2">
                    <Grid item><div className="card-icon-container-2"><img src="/img/master-card-clean.svg" className="icon-card-2" /></div></Grid>
                    <Grid item><div className="card-icon-container-2"><img src="/img/visa-clean.png" className="icon-card-2" /></div></Grid>
                    <Grid item><div className="card-icon-container-2"><img src="/img/american-express2.jpg" className="icon-card-2" /></div></Grid>
                </Grid>

                <Box className="newCardPaymentContainer">
                    <div className="total-processing-card-icons-container">
                        <Grid item className="display-desktop total-processing-card-icons">
                            <Grid container direction="row" spacing={1}>
                                <Grid item><div className="card-icon-container-2"><img src="/img/master-card-clean.svg" className="icon-card-2" /></div></Grid>
                                <Grid item><div className="card-icon-container-2"><img src="/img/visa-clean.png" className="icon-card-2" /></div></Grid>
                                <Grid item><div className="card-icon-container-2"><img src="/img/american-express2.jpg" className="icon-card-2" /></div></Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <form action={window.location.href} class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
                </Box>
            </>}

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseSnackBar}
            >
                <Alert onClose={handleCloseSnackBar} severity={type} >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default NewCardTotalProcessing;