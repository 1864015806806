import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress, IconButton, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, InputAdornment
} from '@material-ui/core';
import MainInput from "./MainInput";
import MainCheckbox from "./MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import RegularInput from "./RegularInput";
import { AuthContext } from "../contexts/AuthContext.js";
import api from "../services/api.js";
import Alert from "@material-ui/lab/Alert";
import { deleteDocument, downloadKbis, getDocuments, getKbis, uploadDocument, downloadDocument, renameDocument } from "../services/FileService";
import { useInterval } from "./Hooks";
import SearchCircular from "./SearchCircular";
import fileDownload from 'js-file-download';
import { getInvoices } from "../services/AccountService";
import { DropzoneArea } from 'material-ui-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';

const AccountOtherDocuments = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [keyDropZoneArea, setkeyDropZoneArea] = useState(1);
    const [documents, setDocuments] = useState([]);
    const [openError, setOpenError] = useState(false);
    const history = useHistory();
    const { auth, authMethods } = useContext(AuthContext);
    const [invoices, setInvoices] = useState([]);

    const [documentSelected, setDocumentSelected] = useState();
    
    const [openConfirmationDelete, setOpenConfirmationDelete] = React.useState(false);
    const handleClickOpenConfirmationDelete = () => {
        setOpenConfirmationDelete(true);
    };
    const handleCloseConfirmationDelete = () => {
        setOpenConfirmationDelete(false);
    };
    
    const [openEdition, setOpenEdition] = React.useState(false);
    const handleClickOpenEdition = () => {
        setOpenEdition(true);
    };
    const handleCloseEdition = () => {
        setOpenEdition(false);
    };

    const [formData, setFormData] = useState({
        nameDocument: undefined
    });
    const desktop = useMediaQuery('(min-width:769px)');

    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    async function getAndSetDocuments() {
        var documentsCall = await getDocuments(3, auth);
        if (documentsCall.data) {
            setDocuments(documentsCall.data);
        }
    }

    useEffect(() => {
        getAndSetDocuments();
    }, []);


    const onChangeFile = (files) => {
        if (files.length > 0) {
            setLoading(true);
            var promises = [];
            files.forEach(file => {
                promises.push(new Promise((resolve, reject) => {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        var dataUpload = {
                            type: file.type,
                            documentType: 3,
                            file: {
                                src: reader.result,
                                title: file.name
                            }
                        }
                        uploadDocument(dataUpload, auth).then((result) => {
                            resolve(result);
                        }, (error) => {
                            console.log(error);
                            resolve(error);
                        });
                        console.log();
                    };
                    reader.onerror = function (error) {
                        console.log('Error: ', error);
                        resolve(error)
                    };
                }))
            });

            Promise.all(promises).then(() => {
                // Vider la liste des fichiers dans l'input.
                setkeyDropZoneArea(keyDropZoneArea + 1);

                // Refresh la liste des fichiers.
                getAndSetDocuments();
                setLoading(false);
            })
        }
    }

    const startDeleteDocument = (doc) => {
        setDocumentSelected(doc);
        handleClickOpenConfirmationDelete();
    }

    const confirmDeleteDocument = () => {
        // Delete document
        deleteDocument(documentSelected.id,auth).then(()=>{
            handleCloseConfirmationDelete();
            getAndSetDocuments();
        })
    }

    const launchDownloadDocument = async (doc) => {
        var docContent = await  downloadDocument(doc.id, auth);
        fileDownload(docContent.data, `${doc.name}`);
    }

    const startEditDocument = (doc) => {
        setDocumentSelected(doc);
        handleClickOpenEdition();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var newDocument = documentSelected;
        newDocument.name = formData.nameDocument+ ".pdf";
        renameDocument(newDocument,auth).then(()=>{
            getAndSetDocuments();
            handleCloseEdition();
            setFormData({nameDocument: undefined});
        });
    }


    return (
        <div className="account-container">
            <div className="account-title">Mes Autres Documents</div>
            <div className="account-body">
                <Grid container direction="row" spacing={3}>
                    {documents.map(doc => {
                        return (
                            <Grid item xs={12} md={4} className="">
                                <div className="document-container">
                                    <div className="preview-doc">
                                        <IconButton color="secondary" className="delete-document-button" onClick={() => {startDeleteDocument(doc)}} >
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton color="secondary" className="download-document-button" onClick={() => {launchDownloadDocument(doc)}} >
                                            <GetAppIcon />
                                        </IconButton>
                                        <img src="/img/file-pdf.png" className="pdf-image"></img>
                                    </div>
                                    <div className="doc-info">
                                        <Grid container direction="row" alignItems="center" justify="space-between" >
                                            <Grid item xs={9}>
                                                <div className="doc-name">{doc.name}</div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <IconButton color="secondary" className="edit-document-button" onClick={() => {startEditDocument(doc)}}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        )
                    })}

                    <Grid item className="drop-zone" xs={12} md={4}  >
                        {loading ?
                            <div className="absolute-full-upper">
                                <CircularProgress />
                            </div> : <></>}
                        <DropzoneArea
                            key={keyDropZoneArea}
                            acceptedFiles={[".pdf"]}
                            dropzoneText={"Glissez et déposez un fichier ici ou cliquez (format PDF)"}
                            showAlerts={false}
                            showPreviewsInDropzone={false}
                            onChange={onChangeFile}
                        />
                    </Grid>
                </Grid>

            </div>

            <Dialog
                open={openEdition}
                onClose={handleCloseEdition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Modification du nom du document"}</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        <RegularInput
                                autoFocus
                                placeholder={documentSelected?documentSelected.name.substring(0, documentSelected.name.length - 4):""}
                                value={formData.nameDocument}
                                onChange={setField("nameDocument")}
                                endAdornment={<InputAdornment position="end">.pdf</InputAdornment>}
                                required
                            ></RegularInput>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" onClick={handleCloseEdition} color="primary" className="header-button" >
                            Annuler
                        </Button>
                        <Button type="submit" color="primary" className="header-button" >
                            Ok
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            
            <Dialog
                open={openConfirmationDelete}
                onClose={handleCloseConfirmationDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Êtes-vous sur de vouloir supprimer ce document: {documentSelected ? documentSelected.name : ""}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmationDelete} color="primary" className="header-button" >
                        Annuler
                    </Button>
                    <Button onClick={confirmDeleteDocument} color="primary" className="header-button" >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default AccountOtherDocuments;
