import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import { useMediaQuery } from '@material-ui/core';


export default function RegularInputRegister(props) {

    const desktop = useMediaQuery('(min-width:769px)');

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: desktop ? "100%" : "96%",
            backgroundColor: '#FFFFFF',
            borderRadius: '4px'
        },
        input: {
            paddingLeft:"13px",
            paddingRight:"13px",
            flex: 1,
        },
        iconButton: {
            padding: "10px 19px 10px 10px",
        }
    }));
    const classes = useStyles();

    return (
        <div component="form" className={classes.root}>
            <InputBase
                className={classes.input}
                {...props}
            />
        </div>
    );
}