import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import { AuthContext } from "../contexts/AuthContext.js";
import { createKbis } from "../services/FileService";
import RegularInput from "../components/RegularInput";
import { askForUnsubscribe } from "../services/AccountService";

const Unsubscribe = () => {

    const history = useHistory();
    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const desktop = useMediaQuery('(min-width:769px)');
    const { auth } = useContext(AuthContext);

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: undefined,
        firstName: undefined,
        email: undefined
    });

    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        setOpen(false);
        e.preventDefault();
        var result = await askForUnsubscribe(formData.email) ;
        setOpen(true);
    }

    const goToContact = () => {
        history.push("/accueil/contact");
    }

    return (<div>
        <div className="title-generic">
            Désinscription
        </div>
        <div className="white-container white-container-content">
            <Grid container direction="row" justify={desktop?"flex-center":"center"} >
                <Grid item xs={12} md={5} >
                    <form onSubmit={handleSubmit}>
                        <Collapse in={open}>
                            <Alert
                                style={{ marginBottom: "15px" }}
                                severity="success"
                                action={
                                    <i
                                        className="fas fa-times"
                                        style={{ color: "inherit", cursor: "pointer" }}
                                        onClick={() => setOpen(false)}
                                    ></i>
                                }
                            >
                                Un email vous a été envoyé avec la procèdure de désinscription.
                    </Alert>
                        </Collapse>
                        <div className="form-lign-generic">

                            <div className="payment-label">Entrez votre nom</div>
                            <RegularInput
                                placeholder="Nom"
                                value={formData.name}
                                onChange={setField("name")}
                                required
                                type="text">
                            </RegularInput>
                        </div>
                        <div className="form-lign-generic">
                            <div className="payment-label">Entrez votre prénom</div>
                            <RegularInput
                                placeholder="Prénom"
                                value={formData.firstName}
                                onChange={setField("firstName")}
                                required
                                type="text">
                            </RegularInput>
                        </div>
                        <div className="form-lign-generic">
                            <div className="payment-label">Entrez votre email</div>
                            <RegularInput
                                placeholder="E-mail"
                                value={formData.email}
                                onChange={setField("email")}
                                required
                                type="email">
                            </RegularInput>
                        </div>

                        <div style={{ marginTop: "35px" }} >
                            <Grid container direction="column" alignItems={desktop?"flex-center":"center"} spacing={2} >
                                <Grid item >
                                    <Button type="submit" variant="contained" color="primary" className="header-button">
                                        Arrêter mon abonnement
                                    </Button>
                                </Grid>
                                <Grid item >
                                    <Button type="button" onClick={goToContact}  color="primary" className="underline-button">
                                        Demander de l'aide
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </form>

                </Grid>
            </Grid>

            <Grid container direction="column" alignItems="center" justify="center" className="display-mobile" style={{ marginTop: "22px" }}  >
                <Grid item><div className="info-logos">Service indépendant, données générées & accessibles depuis:</div></Grid>
                <Grid container direction="row" alignItems="center" justify="center"  >
                    <Grid item><img src="/img/partner11.png" style={{maxWidth:"90px"}}></img></Grid>
                    <Grid item><img src="/img/partner2.svg" style={{ marginLeft: "17px", marginRight: "19px" }}></img></Grid>
                    <Grid item><img src="/img/partner3.svg" style={{ marginRight: "13px" }}></img></Grid>
                    <Grid item><img src="/img/partner4.svg"></img></Grid>
                </Grid>
            </Grid>

        </div>
    </div>
    )
}
export default Unsubscribe;