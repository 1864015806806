import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, LinearProgress
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';

const Home = () => {

    const history = useHistory();
    const { societe, setSociete } = useContext(DataContext);

    const [formData, setFormData] = useState({
        acceptedTerms: false,
        searchInput: undefined
    });
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [societeState, setSocieteState] = useState({
        loading: true,
        data: []
    });

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        if (formData.acceptedTerms) { setOpen(false) };
    }, [formData]);



    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const setFieldValue = (field) => (value) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const setFieldChecked = (field) => (event) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.checked,
        }));
    };

    const handleSubmit = async (event) => {
        setOpen(false);
        event.preventDefault();
        if (!formData.acceptedTerms) {
            setOpen(true);
        } else {
            setSocieteState({
                loading: true,
                data: []
            });
            handleOpenDialog();
            var s = await getSocietes(formData.searchInput);
            if (s) setSocieteState({
                loading: false,
                data: s.data
            });
        }
    };

    const selectSociete = async (societe) => {
        setSociete(societe);
        history.push("/accueil/recherche");
    }

    const openCGU = (event) => {
        event.preventDefault();
        history.push("/accueil/conditions-generales-utilisation");
    }

    const desktop = useMediaQuery('(min-width:769px)');


    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" className="societe-dialog">
                <DialogTitle>
                    <Grid container direction="row" >
                        <Grid item xs={10} md={11} >
                            <Grid container direction="row" justify="center">
                                <div className="dialog-title display-desktop">Sélectionner votre société</div>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} md={1}  >
                            <Button type="button" onClick={handleCloseDialog}  ><img src="/img/close.svg"></img> </Button>
                        </Grid>
                    </Grid>
                    <div className="dialog-title display-mobile">Sélectionner votre société</div>
                </DialogTitle>
                <DialogContent>
                    {
                        societeState.loading && <LinearProgress />
                    }
                    {societeState.data.map(societe => {
                        return (<div className="societe-container" onClick={() => selectSociete(societe)}>
                            <Grid container  >
                                <Grid item xs={12} md={8}>
                                    <div className="info-dialog"><span className="info-title-dialog">NOM : </span> <span className="info-value-dialog">{societe.name}</span></div>
                                    <div className="info-dialog"><span className="info-title-dialog">SIREN : </span> <span className="info-value-dialog">{societe.siren}</span></div>
                                    <div className="info-dialog"><span className="info-title-dialog">ADRESSE : </span> <span className="info-value-dialog">{societe.adress}</span></div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Grid container alignItems="flex-start" justify="flex-end" style={{ height: "100%" }}>
                                        <Button variant="contained" color="primary" className="dialog-button">
                                            <Grid container justify="center" >
                                                <Grid item>Valider</Grid>
                                            </Grid>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>)
                    })}
                    {!societeState.loading && societeState.data.length == 0 ? <div className="zero-result">Pas de résultat trouvé</div> : <></>}
                </DialogContent>
            </Dialog>
            <Grid container direction={desktop ? 'row' : 'column'}>
                <Grid item xs={12} md={9}>
                    <Typography variant="h1" className="nowrap">Votre <span className="color2">KBIS</span> officiel <span className="display-desktop">de moins de 3 mois</span> en 1 clic</Typography>
                    <Typography variant="h2">Obtenez votre document certifié et mis à jour.<br ></br> Sans délai en téléchargement. </Typography>
                    <div className="white-container">
                        <div className="progress">
                            <div className="progress-state"></div>
                        </div>
                        <div className="green-container-content">
                            <Grid container direction="column" alignItems={desktop?"flex-start":"center"}  >
                                <Grid item><div className="box-step">Étape 1/3</div></Grid>
                                <Grid item><div className="box-title">Recherchez votre SIREN ou <br className="display-mobile"></br> SIRET ou Raison Sociale</div></Grid>
                            </Grid>
                            <form className="main-form main-form-image" onSubmit={handleSubmit}>
                                <MainInput
                                    autoFocus
                                    placeholder={desktop ? "Renseigner le SIREN, le SIRET ou le nom de l'entreprise " : "SIREN, SIRET ou le nom de l'entreprise"}
                                    value={formData.searchInput}
                                    onChange={setField("searchInput")}
                                    required
                                ></MainInput>
                                <FormControlLabel
                                    className="checkbox-input"
                                    control={
                                        <MainCheckbox
                                            checked={formData.acceptedTerms}
                                            onChange={setFieldChecked("acceptedTerms")}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={<span className="label-input color-white">Je reconnais avoir pris connaissance et accepte les <span onClick={openCGU} className="link">conditions générales de ventes</span><span className="display-desktop">, d'utilisation et d’accès à la plateforme.</span></span>}
                                />
                                <Collapse in={open}>
                                    <Alert
                                        style={{ marginBottom: "15px" }}
                                        severity="error"
                                        action={
                                            <i
                                                className="fas fa-times"
                                                style={{ color: "inherit", cursor: "pointer" }}
                                                onClick={() => setOpen(false)}
                                            ></i>
                                        }
                                    >
                                        Les conditions générales d'utilisation doivent être acceptées.
                                    </Alert>
                                </Collapse>
                                <Grid container direction="row" alignItems="center" justify={desktop ? "flex-start" : "center"} >
                                    <Grid item>
                                        <Button type="submit" variant="contained" color="primary" className="cta">
                                            <Grid container alignItems="center" >
                                                <Grid item>Obtenir mon KBIS</Grid>
                                                <img src="/img/arrow-right2.svg" ></img>
                                            </Grid>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} className="right-side-image-container">
                    <img className="right-side-image" src="/img/phone-doc.svg"></img>
                </Grid>
            </Grid>
            <Grid container direction="column" alignItems="center" justify="center" className="display-mobile" style={{ marginTop: "22px" }}  >
                <Grid item><div className="info-logos">Service indépendant, données générées & accessibles depuis:</div></Grid>
                <Grid container direction="row" alignItems="center" justify="center"  >
                    <Grid item><img src="/img/partner11.png" style={{maxWidth: "90px"}}></img></Grid>
                    <Grid item><img src="/img/partner2.svg" style={{ marginLeft: "17px", marginRight: "19px" }}></img></Grid>
                    <Grid item><img src="/img/partner3.svg" style={{ marginRight: "13px" }}></img></Grid>
                    <Grid item><img src="/img/partner4.svg"></img></Grid>
                </Grid>
            </Grid>
            <Grid container spacing={desktop ? 6 : 0}>
                <Grid item xs={12} md={6}>
                    <div className="info-title">Extrait k, Extrait Kbis ou Extrait D1</div>
                    <div className="info-body">
                        L’Extrait K ou extrait Kbis ou extraits D1, sont ce que l’on considère comme étant la carte d’identité d’une entreprise, quelque en soit la taille : Artisans, autoentreprise, microentreprise ou grands groupes, à partir du moment ou celles-ci sont enregistrées au Registre du Commerce & des Sociétés (RCS). Le document compile toutes les informations de la société et fait état, des mentions portées par le greffier chargé de la tenue de ce registre. Il s'agit du seul document officiel prouvant l'identité, l’activité et l'adresse de la structure morale ou physique.<br></br>
                        Dans la majeure partie des cas, dans le cadre de démarches, ou pour être opposable, le document doit être daté de moins de 3 mois.
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="info-title">Prestations portail-des-societes.com</div>
                    <div className="info-body">
                        portail-des-societes.com est un portail vous permettant de télécharger vos Kbis ou autres documents justificatifs avec réception immédiate,  en toute simplicité ! <br />
                        Il est aussi possible de stocker tous les documents indispensables via un espace sécurisé (en savoir plus sur nos services). Notre solution est indépendante des greffes et des tribunaux de commerce, permettant ainsi de recevoir son Kbis rapidement, à moindres frais via notre offre d’essai et sans délai. De plus, notre plateforme donne automatiqueent à partir du premier téléchargement de document à 1€, l’accès à un espace personnel permettant de gérer tous vos documents et informations importantes pour votre société et un historique de vos Kbis certifiés, via un abonnement mensuel sans engagement à 49,90€ par mois (tarifs).
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}
export default Home;