import React, { useState, useContext } from "react";
import Footer from './footer';
import Header from './header';
import {
    Container
} from '@material-ui/core'

function RootLayout(props) {
    return (
        <div className="page-container">
            <div className="main-with-header">
                <Header></Header>
                <div className="main">
                    <Container>
                        <div className="main-content">
                            {props.children}
                        </div>
                    </Container>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default RootLayout