import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import { AuthContext } from "../contexts/AuthContext.js";
import { createKbis } from "../services/FileService";
var dataLayer;

const Services = () => {

    const history = useHistory();
    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const desktop = useMediaQuery('(min-width:769px)');
    const { auth } = useContext(AuthContext);

    const goToHome = () => {
        history.push("/accueil");
    }

    return (<div>
        <div className="title-generic">
            Obtention simplifiée et centralisation de vos documents pros !
        </div>
        <div className="white-container white-container-content">
            <Grid container>
                <Grid item xs={12} md={8}>
                    <div className="subtitle-generic">Services portail-des-societes.com</div>
                    <div className="body-generic">
                        Récupération de KBIS ou justificatif officiel immédiatement et en ligne  <br />
                        Envoi par mail au format PDF <br />
                        Accès à la plateforme de gestion: Historique & Téléchargement des documents<br />
                        Un assistant dédié 6/7J par téléphone<br />
                        Téléchargement de 5 KBIS ou justificatifs officiels mis à jours par mois en 1 clic depuis votre espace<br />
                        Votre document mis à jour tous les 3 mois
                        </div>
                    <div className="subtitle-generic">Tarifs portail-des-societes.com</div>
                    <div className="body-generic">
                        L’accès à la plateforme est facturé sous forme <span className="blue-text">d’abonnement à 49,90€ par mois</span><br />
                        L’offre d’essai à <span className="blue-text">1€ </span> (Frais de services) est valable 48h et donne un accès illimité à la plateforme : <br />
                        Accompagnement et aide dans la gestion et récupération de vos justificatifs mis à jours ; un assistant dédié 6/7J (envoi par mail si vous avez pas accès à votre espace ou besoin en urgence) ainsi que notre service de centralisation et partage de documents professionnels.<br />
                        L’abonnement est <span className="blue-text">sans engagement</span> et peut être résilié quand <span className="blue-text">vous le souhaitez après une période de 4 heures après l'inscription</span>, <span className="">par une simple demande en ligne depuis le formulaire, votre espace personnel ou en contactant le support client au numéro dédié.</span> 

                        </div>
                    <Grid container direction="row" alignItems="center" justify={ "center"} >
                        <Grid item>
                            <Button type="button" variant="contained" color="secondary" className="cta" onClick={goToHome}>
                                <Grid container alignItems="center" >
                                    <Grid item>Accèder {desktop && "à la plateforme"}</Grid>
                                    <img src="/img/arrow-right.svg" ></img>
                                </Grid>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {desktop && <Grid item xs={12} md={4} className="doc-container">
                    <img className="doc-desktop" src="/img/doc-desktop.svg"></img>
                    <img className="specimen-desktop" src="/img/specimen.svg"></img>
                </Grid>}
            </Grid>



        </div>
    </div>
    )
}
export default Services;