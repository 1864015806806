import React, { useState } from "react";
import { Tabs, Tab, Grid, Typography, TextField, Button, InputBase, Paper, Container, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Cookies = () => {
    const [cookiesAreAccepted, setcookiesAreAccepted] = useState(() => {
        const localData = typeof localStorage !== 'undefined' && localStorage.getItem("cookieValidate");
        if (localData == "true") {
            return {
                accepted : true,
                makeDisapearCookies : false
            } ;
        } else {
            return  {
                accepted : false,
                makeDisapearCookies : false
            };
        }
    })

    const history = useHistory();

    const validCookies = () => {
        setcookiesAreAccepted({...cookiesAreAccepted,makeDisapearCookies:true});
        localStorage.setItem("cookieValidate", "true");
        setTimeout(() => {
            setcookiesAreAccepted({...cookiesAreAccepted,accepted:true});
        }, 500)
    }

    const goToPrivacyPolicy = () => {
        history.push("/accueil/politique-de-confidentialite");
    }
    const desktop = useMediaQuery('(min-width:769px)');

    return ( cookiesAreAccepted && !cookiesAreAccepted.accepted  &&
        <div className={"cookies" + (cookiesAreAccepted.makeDisapearCookies ? ' no-cookies' : '')} >
            <Container>
                <Grid container direction="row" alignItems="center" justify="center">
                    <Grid item xs={8}>
                    Ce site utilise des cookies permettant de visualiser des contenus, d'afficher des flux d'informations provenant des réseaux sociaux et d'améliorer le fonctionnement grâce aux statistiques de navigation. 
                    <div onClick={goToPrivacyPolicy} className="link">Politique de confidentialité</div>
                    </Grid>
                    <Grid item xs={4} >
                        <Grid container spacing={desktop?3:2} justify="flex-end" >
                            <Grid item><Button color="primary" variant="contained" onClick={validCookies} className="cookie-button" >Accepter </Button></Grid>
                            <Grid item><Button color="primary" variant="contained" onClick={validCookies} className="cookie-button" >Refuser </Button></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div >
    );
};

export default Cookies;
