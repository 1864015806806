import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import { AuthContext } from "../contexts/AuthContext.js";
import { createKbis } from "../services/FileService";

const PrivacyPolicy = () => {

    const history = useHistory();
    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const desktop = useMediaQuery('(min-width:769px)');
    const { auth } = useContext(AuthContext);

    return (
        <div>
            <div className="title-generic">
                Politique de confidentialité 
            </div>
            <div className="white-container white-container-content">
                <div className="body-generic">
                    Chez OUI LIT LTD (portail-des-societes.com), la protection de vos données personnelles est une priorité.<br></br>
                Lors de votre utilisation du site portail-des-societes.com (ci-après le « Site »), nous sommes amenés à recueillir des données à caractère personnel vous concernant.<br></br>
                Le but de cette politique est de vous informer sur les modalités selon lesquelles nous traitons ces données en conformité avec le Règlement Général sur la Protection des Données (ci-après le « RGPD »).
                </div>

                <div className="subtitle-generic">1. Qui est le responsable de traitement ?</div>
                <div className="body-generic">
                    Le responsable de traitement est la société OUI LIT LTD, société de droit Anglais, immatriculée sous le numéro 13584952 et dont le siège social est situé à Londres.
                </div>

                <div className="subtitle-generic">2. Quelles données collectons-nous ?</div>
                <div className="body-generic">
                    Une donnée à caractère personnel est une donnée qui permet d’identifier un individu directement ou par recoupement avec d’autres données.<br></br>
                Nous collectons des données qui relèvent des catégories suivantes :<br></br>
                •	Des données d’identification (notamment vos nom, prénoms, adresse email et postale, raison sociale) ;<br></br>
                •	Des données relatives à vos commandes (historique des Kbis téléchargés, historique des recherches de sociétés);<br></br>
                •	Des données de connexion (par exemple adresse IP, logs) ; Les données obligatoires sont indiquées lorsque vous nous fournissez vos données. Elles sont signalées par un astérisque et sont nécessaires pour vous fournir nos services.

                </div>

                <div className="subtitle-generic">3. Sur quelles bases légales, pour quelles finalités et pendant combien de temps conservons-nous vos données personnelles ?</div>
                <div className="body-generic">
                    <table>
                        <thead>
                            <tr>
                                <th>Finalités</th>
                                <th>Bases légales</th>
                                <th>Durées de conservation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Fournir nos services disponibles sur notre Site : téléchargement des Kbis, garder un historique pour centraliser vos documents.</td>
                                <td>Exécution de mesures précontractuelles prises à votre demande et/ou exécution d’un contrat que vous avez souscrit</td>
                                <td>Lorsque vous vous êtes créé un compte : vos données sont conservées pour toute la durée de votre compte et augmentée d’un délai de 3 ans à compter de la suppression de votre compte. Lorsque vous ne vous êtes pas créé de compte : vos données sont conservées pendant 3 ans à des fins de prospection. En outre, les données relatives à vos transactions (à l’exception de vos données bancaires) peuvent être archivées à des fins probatoires pendant une durée de 5 ans.</td>
                            </tr>
                            <tr>
                                <td>Exécuter votre commande, effectuer les opérations relatives à la gestion des clients concernant les contrats, commandes, factures, et suivi de la relation avec les clients</td>
                                <td>Exécution d’un contrat que vous avez souscrit</td>
                                <td>Les données personnelles sont conservées pendant toute la durée de la relation contractuelle et augmentée d’un délai de 3 ans à compter de la fin de cette dernière. En outre, les données relatives à vos transactions (à l’exceptions de vos données bancaires) peuvent être archivées à des fins probatoires pendant une durée de 5 ans. Concernant les données relatives à votre carte bancaire, elles peuvent être conservées par notre prestataire de service de paiement (*). Les données relatives au cryptogramme visuel ou CVV2, inscrit sur votre carte bancaire, ne sont pas stockées.</td>
                            </tr>
                            <tr>
                                <td>Constituer un fichier de clients et prospects</td>
                                <td>Notre intérêt légitime à développer et promouvoir notre activité</td>
                                <td>Pour les clients : les données sont conservées pendant toute la durée de la relation commerciale et sont supprimées à l’expiration d’un délai de 3 ans à compter de la fin de la relation commerciale. Pour les prospects : les données sont conservées pendant un délai de 3 ans à compter de votre dernier contact. En tout état de cause, les données relatives à vos transactions (à l’exceptions de vos données bancaires) peuvent être archivées à des fins probatoires pendant une durée de 5 ans.</td>
                            </tr>
                            <tr>
                                <td>Adresser des newsletters, sollicitations et messages promotionnels</td>
                                <td>Pour les clients : notre intérêt légitime à développer et promouvoir notre activité Pour les prospects : votre consentement ou notre intérêt légitime à développer et promouvoir notre activité</td>
                                <td>Les données sont conservées pendant 3 ans à compter de votre dernier contact.</td>
                            </tr>
                            <tr>
                                <td>Répondre à vos demandes d’information</td>
                                <td>Notre intérêt légitime à répondre à vos demandes</td>
                                <td>Les données sont conservées pendant le temps nécessaire au traitement de votre demande d’information et supprimées une fois la demande d’information traitée.</td>
                            </tr>
                            <tr>
                                <td>Se conformer aux obligations légales applicables à notre activité</td>
                                <td>Obligations légales et règlementaires</td>
                                <td>Pour les factures : les factures peuvent être archivées pendant une durée de 10 ans. Les données relatives à vos transactions (à l’exception des données bancaires) peuvent être conservées pendant 5 ans.</td>
                            </tr>
                            <tr>
                                <td>Gérer les demandes d’exercice de droits</td>
                                <td>Obligations légales et règlementaires</td>
                                <td>Si nous vous demandons un justificatif d’identité : nous le conservons seulement pendant le temps nécessaire à la vérification d’identité. Une fois la vérification effectuée, le justificatif est supprimé. Si vous exercez votre droit d’opposition à exercer de la prospection : nous conservons cette information pendant 3 ans.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="subtitle-generic">4. Qui sont les destinataires de vos données ?</div>
                <div className="body-generic">
                Auront accès à vos données à caractère personnel : <br></br>
                (i) Le personnel de notre société ;<br></br>
                (ii) Nos sous-traitants : prestataire d’hébergement, prestataire d’envoi de newsletters, prestataire d’envoi de courrier postal (envoi des Kbis), prestataire de paiement, support client, prestataire informatique pour la maintenance applicative, prestataire de conseil et de services comptables;<br></br>
                (iii) Le cas échéant : les organismes publics et privés, exclusivement pour répondre à nos obligations légales.<br></br>
                </div>

                <div className="subtitle-generic">5. Vos données sont-elles susceptibles d’être transférées hors de l’Union européenne ?</div>
                <div className="body-generic">
                Vos données sont conservées et stockées pendant toute la durée des traitements sur des serveurs situés dans l’Union européenne.<br></br>
                Dans le cadre des outils que nous utilisons (voir article sur les destinataires concernant nos sous-traitants), vos données sont susceptibles de faire l’objet de transferts hors de l’Union européenne. Le transfert de vos données dans ce cadre est sécurisé au moyen des outils suivants :<br></br>
                •	Soit ces données sont transférées dans un pays ayant été jugé comme offrant un niveau de protection adéquat par une décision de la Commission européenne ;<br></br>
                •	Soit nous avons conclu avec nos sous-traitants un contrat spécifique encadrant les transferts de vos données en dehors de l’Union européenne, sur la base des clauses contractuelles types entre un responsable de traitement et un sous-traitant approuvées par la Commission européenne ;<br></br>
                •	Soit nous avons recours aux garanties appropriées prévues par la réglementation applicable.

                </div>

                <div className="subtitle-generic">6. Quels sont vos droits sur vos données ?</div>
                <div className="body-generic">
                Vous disposez des droits suivants s’agissant de vos données personnelles : <br></br>
                •	Droit à l’information : c’est justement la raison pour laquelle nous avons rédigé la présente politique. Ce droit est prévu par les articles 13 et 14 du RGPD. <br></br>
                •	Droit d’accès : vous avez le droit d’accéder à tout moment à l’ensemble de vos données personnelles, en vertu de l’article 15 du RGPD. <br></br>
                •	Droit de rectification : vous avez le droit de rectifier à tout moment vos données personnelles inexactes, incomplètes ou obsolètes conformément à l’article 16 du RGPD <br></br>
                •	Droit à la limitation : vous avez le droit d’obtenir la limitation du traitement de vos données personnelles dans certains cas définis à l’article 18 du RGPD. <br></br>
                •	Droit à l’effacement : vous avez le droit d’exiger que vos données personnelles soient effacées, et d’en interdire toute collecte future pour les motifs énoncés à l’article 17 du RGPD <br></br>
                •	Droit d’introduire une réclamation auprès d’une autorité de contrôle compétente, si vous considérez que le traitement de vos données personnelles constitue une violation des textes applicables. (Article 77 du RGPD) <br></br>
                •	Droit de définir des directives relatives à la conservation, à l’effacement et à la communication de vos données personnelles après votre mort, conformément à l’article 40-1 de la loi informatique et libertés. <br></br>
                •	Droit de retirer votre consentement à tout moment : pour les finalités fondées sur le consentement, l’article 7 du RGPD dispose que vous pouvez retirer votre consentement à tout moment. Ce retrait ne remettra pas en cause la légalité du traitement effectué avant le retrait. <br></br>
                •	Droit à la portabilité : selon certaines conditions précisées à l’article 20 du RGPD, vous avez le droit de recevoir les données personnelles que vous nous avez fournies dans un format standard lisible par machine et d’exiger leur transfert au destinataire de votre choix. <br></br>
                •	Droit d’opposition : en vertu de l’article 21 du RGPD, vous avez le droit de vous opposer au traitement de vos données personnelles. Notez toutefois que nous pourrons maintenir leur traitement malgré cette opposition, pour des motifs légitimes ou la défense de droits en justice. <br></br>
                Vous pouvez exercer ces droits en nous écrivant aux coordonnées ci-dessous. Nous pourrons vous demander à cette occasion de nous fournir des informations ou documents complémentaires pour justifier votre identité.
                </div>

                <div className="subtitle-generic">7. Les conditions de traitement et de mise en œuvre de cookies et de traceurs</div>
                <div className="body-generic">
                Nous utilisons différents types de cookies, à des fins différentes.<br></br>
                •	Certains cookies remplissent des fonctions spécifiques ou visent à améliorer votre expérience de navigation et d’autres nous permettent d’analyser la manière dont vous consultez notre site internet afin de l’améliorer.<br></br>
                •	Si certains sont strictement nécessaires, les autres nous aident à personnaliser votre navigation en mémorisant vos préférences et vos paramètres.<br></br>
                Nous utilisons également, outre des cookies, nos journaux de serveur pour l’analyse, le suivi et le diagnostic de nos infrastructures et applications techniques. Ils nous aident à assurer la sécurité de notre exploitation, à enquêter sur les incidents, à surveiller nos performances en matière de réseaux et de télécommunications et à fournir une assistance aux utilisateurs.<br></br>
                En naviguant sur notre site internet, vous acceptez implicitement l’utilisation de :<br></br>
                •	Tous les cookies techniques et de session, stockés pendant la session ;<br></br>
                •	Toutes les préférences des visiteurs (telles que la langue), conservées de manière permanente ;<br></br>
                •	Tous les journaux de serveur, stockés pour une période limitée seulement.<br></br><br></br>

                Comment pouvez-vous accepter ou refuser nos cookies analytiques ?<br></br>
                Nous utilisons des cookies analytiques pour surveiller la navigation de nos utilisateurs et améliorer la qualité de votre expérience de navigation.<br></br>
                Les cookies analytiques sont ajoutés par notre outil d’analyse d’audience, et sont considérés comme des cookies tiers. Ils nous aident à compiler des rapports statistiques agrégés et anonymes sur le parcours de navigation de nos visiteurs.<br></br>
                Vous pouvez les accepter ou les refuser via la bannière affichée au bas de la page. Si vous décidez de ne pas donner votre consentement en ignorant la question, nous n’installerons AUCUN cookie.<br></br>
                Si vous préférez ne pas être suivi, vous pouvez aussi activer la fonctionnalité «Ne pas suivre» de votre navigateur. Elle vous garantira que nous ne suivrons votre activité de navigation d’aucune manière. Cette fonctionnalité est disponible pour la plupart des navigateurs.
                </div>

                <div className="subtitle-generic">8. Point de contact en matière de données personnelles</div>
                <div className="body-generic">
                Email de contact : contact@portail-des-societes.com<br></br>
                Adresse de contact : OUI LIT LTD, Office 4 219 Kensington High Street Kensington London W8 6BD <br/><br/>
                La personne concernée peut s’adresser à la CNIL en cas de difficulté.
                </div>
                
                <div className="subtitle-generic">9. Modifications</div>
                <div className="body-generic">
                Nous pouvons modifier à tout moment la présente politique, afin notamment de nous conformer à toutes évolutions réglementaires, jurisprudentielles, éditoriales ou techniques. Ces modifications s’appliqueront à la date d’entrée en vigueur de la version modifiée. Vous êtes donc invité à consulter régulièrement la dernière version de cette politique. Néanmoins, nous vous tiendrons informé(e) de toute modification significative de la présente politique de confidentialité.
                </div>
                

            </div>
        </div>
        
    )
}
export default PrivacyPolicy;