import React, { useState, useContext, useEffect } from "react";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery, Box, CircularProgress, IconButton, ButtonBase
} from '@material-ui/core';
import { DataContext } from '../contexts/DataContext';
import { useHistory } from "react-router-dom";
import useAcquisition from "../components/Acquisition";
import { createAvis } from "../services/AvisService";
import { getCommentary } from "../services/CommentaryService";
import { Alert, Skeleton } from "@material-ui/lab";

const PaymentAvis = () => {

    const history = useHistory();
    const [blurInfos, setBlurInfos] = useState(false);
    const [avis, setAvis] = useState(false);
    const [commentary, setCommentary] = useState(null);
    const [openLinkCopied, setOpenLinkCopied] = useState(false);

    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const acquisition = useAcquisition();
    const desktop = useMediaQuery('(min-width:769px)');

    const avisClicked = () => {

        createAvis({
            email: user.email,
            origin: acquisition.origin,
            referrer: acquisition.referrer,
            ip: acquisition.ip,
            companyName: societe.name,
            companySIREN: societe.siren,
            companyIsInsee: !societe.hasKbis
        }).then(res => {
            window.location.href = "https://fr.trustpilot.com/evaluate/portail-des-societes.com?utm_medium=trustbox&utm_source=TrustBoxReviewCollector"
        })
    }

    const copyCommentary = () => {
        setOpenLinkCopied(true);
        navigator.clipboard.writeText(commentary)
    }

    async function getAndSetCommentary() {
        var response = await getCommentary();
        if (response && response != null && response != "") {
            console.log(response);
            setCommentary(response);
        }
    }

    useEffect(() => {
        getAndSetCommentary();
    }, []);


    return (
        <Box sx={{
            "-webkit-filter": blurInfos ? "blur(2.5px)" : "blur(0px)",
            "-moz-filter": blurInfos ? "blur(2.5px)" : "blur(0px)",
            "-o-filter": blurInfos ? "blur(2.5px)" : "blur(0px)",
            "-ms-filter": blurInfos ? "blur(2.5px)" : "blur(0px)",
            "filter": blurInfos ? "blur(2.5px)" : "blur(0px)",
        }}>
            <Typography variant="h1">Votre <span className="display-desktop">Extrait</span> <span className="color2">KBIS</span> Officiel à jour est prêt   </Typography>
            <Typography variant="h2">Service indépendant de téléchargement et de centralisation de documents professionnels</Typography>
            <div className="white-email-container" >
                <Grid container direction="row">
                    <Grid item xs={12} md={7} className="">
                        <div className="progress">
                            <div className="progress-state progress-state-3"></div>
                        </div>
                        <div className="white-email-container-right green-container-content-no-image-avis " >
                            <Grid container direction="column" justifyContent="center" alignItems="center" >
                                <Grid item>
                                    <FiveStars />
                                </Grid>
                                <Grid item><div className="avis-title">Votre KBIS GRATUIT contre un AVIS </div></Grid>
                                <Grid item><div className="avis-subtitle">Laissez nous votre avis sur votre expérience et vous recevrez sur l’e-mail : <span style={{ color: "#3400C8" }}>{user.email}</span> votre KBIS gratuit.</div></Grid>
                                <Grid item className="avis-commentary-title">
                                    Exemple d'avis:
                                </Grid>
                                <Grid item>
                                    <Collapse in={openLinkCopied}>
                                        <Alert
                                            style={{ marginBottom: "15px" }}
                                            severity="success"
                                            action={
                                                <i
                                                    className="fas fa-times"
                                                    style={{ color: "inherit", cursor: "pointer" }}
                                                    onClick={() => setOpenLinkCopied(false)}
                                                ></i>
                                            }
                                        >
                                            Commentaire copié ! 
                                        </Alert>
                                    </Collapse>
                                </Grid>
                                <Grid item className="avis-commentary">
                                    {
                                        commentary ?
                                            <ButtonBase focusRipple onClick={() => copyCommentary()}>
                                                <Grid container direction="row" >
                                                    <Grid item xs={11} className="avis-commentary-value">{commentary}</Grid>
                                                    <Grid item xs={1}>
                                                        <Grid container direction="column" justifyContent="center" alignItems="center">
                                                            <Grid item>
                                                                <img src="/img/copy.png" className="avis-copy-icon" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ButtonBase> :
                                            <>
                                                <Skeleton />
                                                <Skeleton width="60%" />
                                                <Skeleton width="75%" />
                                            </>
                                    }
                                </Grid>
                                <Grid item>
                                    <Button color="primary" variant="contained" className="cta-avis" onClick={avisClicked}>Je laisse mon avis<img src="/img/arrow-right2.svg" /></Button>
                                </Grid>
                                <Grid item className="avis-content">
                                    Nous cherchons constamment à nous améliorer pour mieux vous servir, c’est pourquoi nous vous proposons de simplement nous décrire en quelques mots votre expérience sur le site, et bénéficiez ainsi de <span style={{ fontWeight: 700 }}>Vos Kbis & accès gratuit</span> pour vous remercier.  <span style={{ fontWeight: 700 }}>Profitez en</span> vite, offre limitée dans le temps !*
                                </Grid>
                                <Grid item style={{ cursor: "pointer", marginTop: "20px important" }} className="avis-nothanks-item">
                                    <a href="/accueil/paiement" >
                                        <div className="avis-nothanks">
                                            Non merci, je ne veux pas profiter de cette offre gratuite, continuer vers mon moyen de paiement.
                                        </div>
                                    </a>
                                </Grid>
                                <Grid item container direction="row" className="avis-checks">
                                    <Grid item >
                                        <AvisChecks text="KBIS & Justificatifs" />
                                    </Grid>
                                    <Grid item className="display-mobile">
                                        <AvisChecks text="Centralisation" />
                                    </Grid>
                                    <Grid item className="display-desktop">
                                        <AvisChecks text="Centralisation de vos documents" />
                                    </Grid>
                                    <Grid item >
                                        <AvisChecks text="Assistance personnelle" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5} >
                        <div className="payment2-right-part"  >
                            <Grid container direction="column"   >
                                <Grid item>
                                    {desktop ?
                                        <Grid container alignItems="center" spacing={2} >
                                            <Grid item ><div className="box-step box-step-2">Étape 3/3</div></Grid>
                                            <Grid item ><div className="payment2-right-part-title">{desktop ? "Téléchargement" : "Résumé de la commande & création de compte"} </div> </Grid>
                                        </Grid> :
                                        <Grid container alignItems="center" spacing={2} >
                                            <Grid item xs={3}><div className="box-step box-step-2">Étape 3/3</div></Grid>
                                            <Grid item xs={9}><div className="payment2-right-part-title">{desktop ? "Téléchargement" : "Résumé de la commande & création de compte"} </div> </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                <div className="payment2-right-part-divider display-desktop"></div>
                                <Grid item className="display-desktop"><div className="payment2-right-part-title2">Résumé de la commande</div> </Grid>
                                <div className="payment2-right-part-divider"></div>
                                <Grid item>
                                    <div className="payment2-right-part-title3">Raison sociale</div>
                                    <div className="payment2-right-part-description">{societe.name}</div>
                                </Grid>
                                <div className="payment2-right-part-divider"></div>
                                <Grid item>
                                    <div className="payment2-right-part-title3">Numéro SIREN</div>
                                    <div className="payment2-right-part-description">{societe.siren}</div>
                                </Grid>
                                <div className="payment2-right-part-divider"></div>
                                <Grid item>
                                    <div className="payment2-right-part-title3">Email</div>
                                    <div className="payment2-right-part-description">{user.email}</div>
                                </Grid>
                                <div className="payment2-right-part-divider"></div>
                                <Grid item>
                                    <div className="payment2-right-part-title3">Votre commande</div>
                                    <div className="payment2-right-part-description">*Justificatif officiel gratuit</div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid >
            </div >
            <Grid container direction="column" alignItems="center" justify="center" className="display-mobile" style={{ marginTop: "22px" }}  >
                <Grid item><div className="info-logos">Service indépendant, données générées & accessibles depuis:</div></Grid>
                <Grid container direction="row" alignItems="center" justify="center"  >
                    <Grid item><img src="/img/partner11.png" style={{maxWidth:"90px"}}></img></Grid>
                    <Grid item><img src="/img/partner2.svg" style={{ marginLeft: "17px", marginRight: "19px" }}></img></Grid>
                    <Grid item><img src="/img/partner3.svg" style={{ marginRight: "13px" }}></img></Grid>
                    <Grid item><img src="/img/partner4.svg"></img></Grid>
                </Grid>
            </Grid>
        </Box >
    )
}

const FiveStars = () => {
    return (
        <Grid container direction="row" justifyContent="center" className="fivestar-container">
            {
                [1, 2, 3, 4, 5].map((index) =>
                    <Grid item key={index}>
                        <img src="/img/ant-design_star-filled.svg" />
                    </Grid>
                )
            }
        </Grid>
    )
}

const AvisChecks = (props) => {
    return (
        <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
                <img src="/img/circle_check.svg" />
            </Grid>
            <Grid item className="avis-check-text">
                {props.text}
            </Grid>
        </Grid>
    )
}

export default PaymentAvis;