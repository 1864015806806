import api from "../../services/api";

export const cancelSubscription = async (auth) => {
    return await api
        .get("/api/paymenttp/cancelaccount", {
            headers: {
                Authorization: "Bearer " + auth.accessToken,
                Accept: "application/json",
            },
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
}

export const getNewCardCheckoutId = async (auth) => {
    var checkoutId = await api.post("api/paymenttp/newcardcheckout",{},
        {
            headers: {
                Authorization: "Bearer " + auth.accessToken,
                Accept: "application/json",
            },
        }).catch((error) => {
            console.log(error);
            return false;
        });
    if (checkoutId.data) return checkoutId.data;
    else return false;
}


export const getCheckoutId = async (email) => {
    var checkoutId = await api.post("api/paymenttp/createcheckout",
        {
            email
        },
        {
            headers: {
                Accept: "application/json",
            },
        }).catch((error) => {
            console.log(error);
            return false;
        });
    if (checkoutId.data) return checkoutId.data;
    else return false;
}

export const proceedNewCard = async (auth, origin, referrer, ip, paymentId) => {
    var payment = await api.post("api/paymenttp/proceednewcard",
        {
            origin,
            referrer,
            ip,
            paymentId
        },
        {
            headers: {
                Authorization: "Bearer " + auth.accessToken,
                Accept: "application/json",
            },
        }).catch((error) => {
            console.log(error);
            return false;
        });
    if (payment.data) return payment.data;
    else return false;
}

export const proceedPayment = async (email, origin, referrer, ip, paymentId) => {
    var payment = await api.post("api/paymenttp/proceedPayment",
        {
            email,
            origin,
            referrer,
            ip,
            paymentId
        },
        {
            headers: {
                Accept: "application/json",
            },
        }).catch((error) => {
            console.log(error);
            return false;
        });
    if (payment.data) return payment.data;
    else return false;
}