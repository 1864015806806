import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, LinearProgress
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';

const Research = () => {

    const history = useHistory();
    const { societe, setSociete } = useContext(DataContext);

    const [formData, setFormData] = useState({
        acceptedTerms: false,
        searchInput: undefined
    });
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [societeState, setSocieteState] = useState({
        loading: true,
        data: []
    });

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        if (formData.acceptedTerms) { setOpen(false) };
    }, [formData]);



    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const setFieldValue = (field) => (value) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const setFieldChecked = (field) => (event) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.checked,
        }));
    };

    const handleSubmit = async (event) => {
        setOpen(false);
        event.preventDefault();
        if (!formData.acceptedTerms) {
            setOpen(true);
        } else {
            setSocieteState({
                loading: true,
                data: []
            });
            handleOpenDialog();
            var s = await getSocietes(formData.searchInput);
            if (s) setSocieteState({
                loading: false,
                data: s.data
            });
        }
    };

    const selectSociete = async (societe) => {
        setSociete(societe);
        history.push("/accueil/recherche");
    }

    const openCGU = (event) => {
        event.preventDefault();
        history.push("/accueil/conditions-generales-utilisation");
    }

    const desktop = useMediaQuery('(min-width:769px)');


    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" className="societe-dialog">
                <DialogTitle>
                    <Grid container direction="row" >
                        <Grid item xs={10} md={11} >
                            <Grid container direction="row" justify="center">
                                <div className="dialog-title display-desktop">Sélectionner votre société</div>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} md={1}  >
                            <Button type="button" onClick={handleCloseDialog}  ><img src="/img/close.svg"></img> </Button>
                        </Grid>
                    </Grid>
                    <div className="dialog-title display-mobile">Sélectionner votre société</div>
                </DialogTitle>
                <DialogContent>
                    {
                        societeState.loading && <LinearProgress />
                    }
                    {societeState.data.map(societe => {
                        return (<div className="societe-container" onClick={() => selectSociete(societe)}>
                            <Grid container  >
                                <Grid item xs={12} md={8}>
                                    <div className="info-dialog"><span className="info-title-dialog">NOM : </span> <span className="info-value-dialog">{societe.name}</span></div>
                                    <div className="info-dialog"><span className="info-title-dialog">SIREN : </span> <span className="info-value-dialog">{societe.siren}</span></div>
                                    <div className="info-dialog"><span className="info-title-dialog">ADRESSE : </span> <span className="info-value-dialog">{societe.adress}</span></div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Grid container alignItems="flex-start" justify="flex-end" style={{ height: "100%" }}>
                                        <Button variant="contained" color="primary" className="dialog-button">
                                            <Grid container justify="center" >
                                                <Grid item>Valider</Grid>
                                            </Grid>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>)
                    })}
                    {!societeState.loading && societeState.data.length == 0 ? <div className="zero-result">Pas de résultat trouvé</div> : <></>}
                </DialogContent>
            </Dialog>
            <Grid container direction={desktop ? 'row' : 'column'}>
                <Grid item xs={12} md={9}>
                    <img src="/img/research-desktop-1.png" className="display-desktop" style={{ width: "850px", maxWidth: "100%" }}></img>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }} className="display-mobile">
                        <img src="/img/research-mobile-1.png"
                            style={{
                                width: "300px", maxWidth: "100%"
                            }}></img>
                    </div>
                    <div className="white-container">
                        <div className="progress">
                            <div className="progress-state"></div>
                        </div>
                        <div className="green-container-content">
                            <Grid container direction="column" alignItems={desktop ? "flex-start" : "center"}  >
                                <Grid item><div className="box-step">Étape 1/3</div></Grid>
                                <img src="img/reserach-desktop-2.png" className="display-desktop" style={{ width: "580px", maxWidth: "100%" }}></img>
                                <img src="img/research-mobile-2.png" className="display-mobile" style={{ width: "230px", maxWidth: "100%" }}></img>
                            </Grid>
                            <form className="main-form main-form-image" onSubmit={handleSubmit}>
                                <MainInput
                                    autoFocus
                                    placeholder={desktop ? "Renseigner le nom de l'entreprise ou son numéro" : "le nom de l'entreprise"}
                                    value={formData.searchInput}
                                    onChange={setField("searchInput")}
                                    required
                                ></MainInput>
                                <FormControlLabel
                                    className="checkbox-input"
                                    control={
                                        <MainCheckbox
                                            checked={formData.acceptedTerms}
                                            onChange={setFieldChecked("acceptedTerms")}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={<span className="label-input color-white">Je reconnais avoir pris connaissance et accepte les <span onClick={openCGU} className="link">conditions générales de ventes</span><span className="display-desktop">, d'utilisation et d’accès à la plateforme.</span></span>}
                                />
                                <Collapse in={open}>
                                    <Alert
                                        style={{ marginBottom: "15px" }}
                                        severity="error"
                                        action={
                                            <i
                                                className="fas fa-times"
                                                style={{ color: "inherit", cursor: "pointer" }}
                                                onClick={() => setOpen(false)}
                                            ></i>
                                        }
                                    >
                                        Les conditions générales d'utilisation doivent être acceptées.
                                    </Alert>
                                </Collapse>
                                <Grid container direction="row" alignItems="center" justify={desktop ? "flex-start" : "center"} >
                                    <Grid item>
                                        <Button type="submit" variant="contained" color="primary" className="cta">
                                            <Grid container alignItems="center" >
                                                <Grid item>Obtenir</Grid>
                                                <img src="/img/arrow-right2.svg" ></img>
                                            </Grid>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} className="right-side-image-container">
                    <img className="right-side-image" src="/img/phone-doc.svg"></img>
                </Grid>
            </Grid>

            <Grid container direction="column" alignItems="center" justify="center" className="display-mobile" style={{ marginTop: "22px" }}  >
                <Grid item><div className="info-logos">Service indépendant, données générées & accessibles depuis:</div></Grid>
                <Grid container direction="row" alignItems="center" justify="center"  >
                    <Grid item><img src="/img/partner11.png" style={{ maxWidth: "90px" }}></img></Grid>
                    <Grid item><img src="/img/partner2.svg" style={{ marginLeft: "17px", marginRight: "19px" }}></img></Grid>
                    <Grid item><img src="/img/partner3.svg" style={{ marginRight: "13px" }}></img></Grid>
                    <Grid item><img src="/img/partner4.svg"></img></Grid>
                </Grid>
            </Grid>

            <Grid container style={{marginTop:"20px"}} className="display-desktop">
                <Grid item xs={12} md={6}>
                    <img src="img/research-desktop-3.png"  style={{ width: "580px", maxWidth: "100%" }}></img>
                    </Grid>
                <Grid item xs={12} md={6}>
                    <img src="img/research-desktop-4.png" style={{ width: "580px", maxWidth: "100%" }}></img>
                 </Grid>
            </Grid>
            <Grid container style={{marginTop:"20px"}} className="display-mobile">
                <Grid item xs={12} md={12}>
                    <img src="img/research-mobile-3.png" style={{ width: "100%", maxWidth: "100%" }}></img>
                </Grid>
            </Grid>
        </div >
    )
}
export default Research;