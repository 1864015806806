import React, { useState, useContext, useEffect } from "react";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery, Box
} from '@material-ui/core';
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import { useHistory } from "react-router-dom";
import RegularInput from "../components/RegularInput";
import PaymentFormTotalProcessing from "../components/totalprocessing/PaymentFormTotalProcessing";

const PaymentTotalProcessing = () => {

    const history = useHistory();
    const [blurInfos, setBlurInfos] = useState(false);

    const { societe, setSociete, user, setUser } = useContext(DataContext);

    const [formData, setFormData] = useState({
        email: null
    });



    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const setFieldValue = (field) => (value) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const setFieldChecked = (field) => (event) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.checked,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setUser((prevState) => ({
            ...prevState,
            email: formData.email,
        }));

    };

    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <Box  sx={{
            "-webkit-filter": blurInfos ? "blur(2.5px)": "blur(0px)",
            "-moz-filter": blurInfos ? "blur(2.5px)": "blur(0px)",
            "-o-filter": blurInfos ? "blur(2.5px)": "blur(0px)",
            "-ms-filter": blurInfos ? "blur(2.5px)": "blur(0px)",
            "filter": blurInfos ? "blur(2.5px)": "blur(0px)",
        }}>
            <Typography variant="h1">Votre <span className="display-desktop">Extrait</span> <span className="color2">KBIS</span> Officiel à jour est prêt   </Typography>
            <div className="text-header-payment">
                
                Service indépendant de téléchargement et de centralisation de documents professionnels,<br className="display-desktop" /> Offre d’essai de 1€ pour 48h, puis 49,90€ par mois sans engagement de durée.
            </div>
            <div className="white-email-container" >
                <Grid container direction="row">
                    <Grid item xs={12} md={7} className="">
                        <div className="progress">
                            <div className="progress-state progress-state-3"></div>
                        </div>
                        <div className="white-email-container-right green-container-content-no-image " >
                            <Grid container direction={desktop ? "row" : "column"}   >
                                <Grid item>
                                    <Grid container direction="column">
                                        <Grid item><div className="box-title box-title2">Paiement 100% sécurisé </div></Grid>
                                        <Grid item><div className="box-due-amount">Montant dû à ce jour </div></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-between" className="price-total-2">
                                <Grid item><div className="price-info-2 color-white">Tarif  </div></Grid>
                                <Grid item><div className="price-info-2 color-white">1€</div></Grid>
                            </Grid>
                            <div className="price-divider-2"></div>
                            {/* 
                            <Grid container direction="row" justify="space-between" className="price-total-2">
                                <Grid item><div className="price-info-sub-2 color-white">Accès complet - Essai  </div></Grid>
                                <Grid item><div className="price-info-sub-2 color-white">0,50€</div></Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-between" className="price-total-2" style={{marginBottom:"14px"}}>
                                <Grid item><div className="price-info-sub-2 color-white">Vérification administrative  </div></Grid>
                                <Grid item><div className="price-info-sub-2 color-white">0,50€</div></Grid>
                            </Grid>*/}

                            <div className="">
                                <PaymentFormTotalProcessing blurInfos={blurInfos} setBlurInfos={setBlurInfos}></PaymentFormTotalProcessing>
                            </div>
                        </div>
                        <Grid container direction="row" className="email-icons-container  display-desktop">
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Grid container direction="column" alignItems="center" justify="center">
                                            <Grid item><img className="email-icon" src="/img/icon-green1.svg"></img></Grid>
                                            <Grid item className="price-icon-description">Paiement sécurisé</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container direction="column" alignItems="center" justify="center">
                                            <Grid item><img className="email-icon" src="/img/icon-green2.svg"></img></Grid>
                                            <Grid item className="price-icon-description">Document officiel</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container direction="column" alignItems="center" justify="center">
                                            <Grid item><img className="email-icon" src="/img/icon-green3.svg"></img></Grid>
                                            <Grid item className="price-icon-description">Protection des données</Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5} >
                        <div className="payment2-right-part"  >
                            <Grid container direction="column"   >
                                <Grid item>
                                    {desktop ?
                                        <Grid container alignItems="center" spacing={2} >
                                            <Grid item ><div className="box-step box-step-2">Étape 3/3</div></Grid>
                                            <Grid item ><div className="payment2-right-part-title"> Extrait KBIS <span style={{fontWeight:700}}>{societe.name}</span>   </div> </Grid>
                                        </Grid> :
                                        <Grid container alignItems="center" spacing={2} >
                                            <Grid item xs={3}><div className="box-step box-step-2">Étape 3/3</div></Grid>
                                            <Grid item xs={9}><div className="payment2-right-part-title"> Extrait KBIS <span style={{fontWeight:600}}>{societe.name}</span> </div> </Grid>
                                        </Grid>
                                    }

                                </Grid>
                                <div className="payment2-right-part-divider display-desktop"></div>
                                <Grid item>
                                    <div className="payment2-right-part-title3">Raison sociale</div>
                                    <div className="payment2-right-part-description">{societe.name}</div>
                                    {desktop? <img className="specimen-img" src="/img/specimen.png"></img> : <img className="specimen-img" src="/img/specimen-mobile.png"></img> }
                                    
                                </Grid>
                                <div className="payment2-right-part-divider"></div>
                                <Grid item>
                                    <div className="payment2-right-part-title3">Numéro SIREN</div>
                                    <div className="payment2-right-part-description">{societe.siren}</div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Grid container direction="row" className="email-icons-container display-mobile" style={{ marginBottom: "75px" }} >
                <Grid item xs={12} >
                    <Grid container direction="row">
                        <Grid item xs={4}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item><img className="email-icon" src="/img/icon-green1.svg"></img></Grid>
                                <Grid item className="email-icon-description">Paiement sécurisé</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item><img className="email-icon" src="/img/icon-green2.svg"></img></Grid>
                                <Grid item className="email-icon-description">Document officiel</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item><img className="email-icon" src="/img/icon-green3.svg"></img></Grid>
                                <Grid item className="email-icon-description">Protection des données</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="column" alignItems="center" justify="center" className="display-mobile" style={{ marginTop: "22px" }}  >
                <Grid item><div className="info-logos">Service indépendant, données générées & accessibles depuis:</div></Grid>
                <Grid container direction="row" alignItems="center" justify="center"  >
                    <Grid item><img src="/img/partner11.png" style={{maxWidth:"90px"}}></img></Grid>
                    <Grid item><img src="/img/partner2.svg" style={{ marginLeft: "17px", marginRight: "19px" }}></img></Grid>
                    <Grid item><img src="/img/partner3.svg" style={{ marginRight: "13px" }}></img></Grid>
                    <Grid item><img src="/img/partner4.svg"></img></Grid>
                </Grid>
            </Grid>
        </Box >
    )
}
export default PaymentTotalProcessing;