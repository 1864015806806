import api from "./api";


export const createKbis = async (data, auth) => {
    return await api.post(`/api/file/create`,data, {
        headers: {
            Authorization: "Bearer " + auth.accessToken,
            Accept: "application/json",
        },
    }).catch((error) => {
        console.log(error);
        return false;
    });
}

export const getKbis = async (auth) => {
    return await api.get(`/api/file/mine`, {
        headers: {
            Authorization: "Bearer " + auth.accessToken,
            Accept: "application/json",
        },
    }).catch((error) => {
        console.log(error);
        return false;
    });
}

export const downloadKbis = async (kbisId,auth) => {
    return await api.get(`/api/file/download?kbisId=${kbisId}`, {
        headers: {
            Authorization: "Bearer " + auth.accessToken,
        },
        responseType: 'blob',
    }).catch((error) => {
        console.log(error);
        return false;
    });
}

export const uploadDocument = async (data, auth) => {
    return await api.post(`/api/file/upload`,data, {
        headers: {
            Authorization: "Bearer " + auth.accessToken,
            Accept: "application/json",
        },
    }).catch((error) => {
        console.log(error);
        return false;
    });
}


export const getDocuments = async (documentType,auth) => {
    return await api.get(`/api/file/documents?documentType=${documentType}`, {
        headers: {
            Authorization: "Bearer " + auth.accessToken,
            Accept: "application/json",
        },
    }).catch((error) => {
        console.log(error);
        return false;
    });
}

export const deleteDocument = async (docId,auth) => {
    return await api.delete(`/api/file/document?documentId=${docId}`, {
        headers: {
            Authorization: "Bearer " + auth.accessToken,
            Accept: "application/json",
        },
    }).catch((error) => {
        console.log(error);
        return false;
    });
}

export const downloadDocument = async (documentId,auth) => {
    return await api.get(`/api/file/document-download?documentId=${documentId}`, {
        headers: {
            Authorization: "Bearer " + auth.accessToken,
        },
        responseType: 'blob',
    }).catch((error) => {
        console.log(error);
        return false;
    });
}


export const renameDocument = async (document, auth) => {
    return await api.put(`/api/file/rename-document`,document, {
        headers: {
            Authorization: "Bearer " + auth.accessToken,
            Accept: "application/json",
        },
    }).catch((error) => {
        console.log(error);
        return false;
    });
}