import { Box, Button, CircularProgress, Collapse, FormControlLabel, Grid, useMediaQuery } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { getCheckoutId, proceedPayment } from "./PaymentTotalProcessingService";
import './totalProcessing-register.css';
import $ from 'jquery';
import MainCheckbox from "../MainCheckbox";
import { useHistory, useLocation } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { DataContext } from "../../contexts/DataContext";
import useAcquisition from "../Acquisition";
import { AuthContext } from "../../contexts/AuthContext";
import queryString from 'query-string';
import api from "../../services/api";

const PaymentFormRegisterTotalProcessing = (props) => {

    const [checkoutId, setCheckoutId] = useState(null);
    const history = useHistory();
    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const { search } = useLocation();
    const acquisition = useAcquisition();
    const { authMethods } = useContext(AuthContext);
    const [paymentError, setPaymentError] = useState(false);

    function setConfigurationPaymentForm() {
        window.wpwlOptions = {
            locale: "fr",
            brandDetection: true,
            brandDetectionType: "binlist",
            onReady: function () {
                $(".wpwl-group-cardHolder").before($(".wpwl-group-expiry"));
                $(".wpwl-group-cardNumber").after($(".wpwl-group-cardHolder"));
                $('.wpwl-label-cardNumber').html('Entrez votre numéro de carte');
                $('.wpwl-label-expiry').html('Date de péremption');
                $('.wpwl-label-cvv').html('CVC');
                $('.wpwl-control-iframe.wpwl-control-cardNumber').attr("placeholder", "Numéro de carte");
                $('.wpwl-control.wpwl-control-expiry').attr("placeholder", "MM / AA");
                $('.wpwl-control-iframe.wpwl-control-cvv').attr("placeholder", "CVC");
                $('.wpwl-button-pay').html('<span class="MuiButton-label"><div class="MuiGrid-root MuiGrid-container MuiGrid-align-items-xs-center MuiGrid-justify-content-xs-space-between"><div class="MuiGrid-root MuiGrid-item"></div><div class="MuiGrid-root MuiGrid-item">Valider</div><img src="/img/arrow-right2.svg"></div></span><span class="MuiTouchRipple-root"></span>');
            },
            onBeforeSubmitCard: preCheck
        };
    }

    function validateHolder() {
        var holder = $('.wpwl-control-cardHolder').val();
        if (holder.trim().length < 2) {
            $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">Invalid card holder</div>');
            return false;
        }
        return true;
    }

    function preCheck() {
        setOpenError(false);
        setLoading(true);
        if (!window.acceptedTerms) {
            setLoading(false);
            setLoadingDialog(false);
            setError("Les conditions générales d'utilisation doivent être acceptées.");
            setOpenError(true);
            return false;
        }
        if (!validateHolder()) {
            setLoading(false);
            setLoadingDialog(false);
            setError("Le nom du titulaire de la carte est obligatoire.");
            setOpenError(true);
            return false;
        }
        return true;
    }

    async function prepareCheckout() {
        var checkoutInfo = await getCheckoutId(props.email);
        if (checkoutInfo.existAndIsSubscribed) {
            setError("Un compte actif existe déjà avec cet email. Connectez-vous puis allez sur l'onglet 'Nouveau KBIS' pour générer un nouveau KBIS.");
            setOpenError(true);
        } else {
            if (checkoutInfo.id != undefined && checkoutInfo.id != null) {
                setCheckoutId(checkoutInfo.id);
            }
        }
    }

    useEffect(() => {

        async function handlePaymentProcess(paymentId) {
            console.log("Handling payment process")
            setPaymentError(false);
            await proceedPayment(user.email, acquisition.origin, acquisition.referrer, acquisition.ip, paymentId)
                .then((data) => {
                    // Si l'utilisateur a deja un abonnement actif on lui informe qu'il doit se connecter.
                    if (data.existAndIsSubscribed) {
                        setLoadingDialog(false);
                        props.setBlurInfos(false);
                        setLoading(false);
                        setError("Un compte actif existe déjà avec cet email. Connectez-vous puis allez sur l'onglet 'Nouveau KBIS' pour générer un nouveau KBIS.");
                        setOpenError(true);
                    } else {
                        // Login
                        api.post("/Token",
                            "grant_type=" + data.grant_type + "&username=" + data.username + "&password=" + data.password
                            , {
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                            }).then((tokenObject) => {
                                authMethods.login(data.user, tokenObject.data.access_token, tokenObject.data.access_token, data.isSubscribed);
                                history.push("/accueil/mon-compte");
                            }).catch((error) => {
                                setLoadingDialog(false);
                                props.setBlurInfos(false);
                                setLoading(false);
                                setError("Une erreur s'est produite.");
                                setOpenError(true);
                                setPaymentError(true);
                            });
                    }
                })
                .catch((error) => {
                    setLoadingDialog(false);
                    props.setBlurInfos(false);
                    setLoading(false);
                    if (error.response.status == 403 && error.response.data.response != undefined && error.response.data.response == "Fraud") {
                        setError("Banque bloquée suite à des fraudes à répétition. Des procédures sont en cours, veuillez nous contacter pour plus d'informations.");
                    } else {
                        setError("Une erreur s'est produite.");
                    }
                    setOpenError(true);
                    setPaymentError(true);

                });
        }

        const values = queryString.parse(search)
        // Back from Total Processing payment
        if (values.id == null) {
            setConfigurationPaymentForm();
            prepareCheckout();
            window.acceptedTerms = false;
        } else {
            handlePaymentProcess(values.id);
        }
    }, []);




    const [loading, setLoading] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [error, setError] = useState("");
    const [openError, setOpenError] = useState(false);
    const [formData, setFormData] = useState({
        acceptedTerms: false,
        searchInput: undefined
    });

    const setAcceptedTermsChecked = (field) => (event) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.checked,
        }));
        window.acceptedTerms = event.target.checked;
    };
    const goToCGU = (event) => {
        event.preventDefault();
        history.push("/accueil/conditions-generales-utilisation");
    }

    const goToPrivacyPolicy = () => {
        history.push("/accueil/politique-de-confidentialite");
    }

    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <div>
            <Helmet>
                {checkoutId && <script src={(process.env.NODE_ENV === "production" ? 'https://eu-prod.oppwa.com/' : 'https://eu-test.oppwa.com/') + `v1/paymentWidgets.js?checkoutId=${checkoutId}`}></script>}
            </Helmet>
            <Collapse in={openError}>
                <Alert
                    style={{ marginBottom: "15px" }}
                    severity="error"
                    action={
                        <i
                            className="fas fa-times"
                            style={{ color: "inherit", cursor: "pointer" }}
                            onClick={() => setOpenError(false)}
                        ></i>
                    }
                >
                    {error}
                </Alert>
            </Collapse>
            {
                paymentError &&
                <Button variant="contained" color="primary" className="cta" onClick={() => {
                    history.push("/accueil/paiement")
                    history.go(0)
                }}>Réessayer</Button>
            }
            {checkoutId && <>
                <Grid container direction="row" spacing={1} className="display-mobile card-icon-div-2">
                    <Grid item><div className="card-icon-container-2"><img src="/img/master-card-clean.svg" className="icon-card-2" /></div></Grid>
                    <Grid item><div className="card-icon-container-2"><img src="/img/visa-clean.png" className="icon-card-2" /></div></Grid>
                    <Grid item><div className="card-icon-container-2"><img src="/img/american-express2.jpg" className="icon-card-2" /></div></Grid>
                </Grid>

                <Box className="paymentRegisterContainer">
                    <div className="total-processing-card-icons-container">
                        <Grid item className="display-desktop total-processing-card-icons">
                            <Grid container direction="row" spacing={1}>
                                <Grid item><div className="card-icon-container-2"><img src="/img/master-card-clean.svg" className="icon-card-2" /></div></Grid>
                                <Grid item><div className="card-icon-container-2"><img src="/img/visa-clean.png" className="icon-card-2" /></div></Grid>
                                <Grid item><div className="card-icon-container-2"><img src="/img/american-express2.jpg" className="icon-card-2" /></div></Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <form action={window.location.href} class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
                </Box>
                <FormControlLabel
                    className="checkbox-input-payment  checkbox-input-payment-totalprocessing"
                    control={
                        <MainCheckbox
                            checked={formData.acceptedTerms}
                            onChange={setAcceptedTermsChecked("acceptedTerms")}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label={<span className="label-input-small-2" style={{ color: "#9FA0A7" }}>Je reconnais avoir pris connaissance et accepte les <span className="link" onClick={goToCGU}>conditions générales d'utilisation</span> et la <span className="link" onClick={goToPrivacyPolicy}>politique de confidentialité</span>, d'utilisation et d’accès à la plateforme.  </span>}
                />
            </>}


        </div>

    )
}

export default PaymentFormRegisterTotalProcessing;